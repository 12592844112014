import React from 'react'
import ProductsHead from './head'
import { AllProductsDiv, ProductContainer,  Text } from './style'
import {ReactComponent as Spinner} from 'assets/svg/spinner.svg'
import ProductInfo from 'pages/dashboard/addProduct/singleProduct/productData'
import { useAuthContext } from 'context/AuthContext'
import { GETALLPRODUCTS } from 'routes/backend'
import { useQuery } from 'react-query'


export default function Products({history}) {

    const {user} = useAuthContext()

   async function getAllProducts(){
        try{
        const response = await fetch(GETALLPRODUCTS,{
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
        const data = await response.json()
        return data

        }catch(err){
            return err.message
        }       
    }

    const {isLoading, error, data} = useQuery('allProducts',getAllProducts)
    return (
        <ProductContainer>
            <ProductsHead />
            <Text>All Products</Text>
            {console.log('dataa',data)}
            <AllProductsDiv>
                {/* {showAllProducts} */}
                {
                    isLoading ? <Spinner />: !error?
                    data.data.map((product,i)=>(
                        <div onClick={()=>history.push(`/dashboard/product/${product.id}`)} key={i}>
                            <ProductInfo 
                                name={product.productName} 
                                address={product.address} 
                                category={product.category[0]?.name} 
                                amount={product.amount} 
                                image={product.images[1]}
                                brand={product.user.brandName} 
                            />
                        </div>
                    )):null
                }
            </AllProductsDiv>
        </ProductContainer>
    )
}

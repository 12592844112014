// export const baseUrl = 'https://backend-27awp.ondigitalocean.app'
// export const baseUrl = 'https://spottr-dev-ekagk.ondigitalocean.app/docs/'
export const baseUrl = 'https://thespottrapp.herokuapp.com'
// export const baseUrl = 'https://prod.thespottrapp.com'

//corporate
export const SIGNUPUSER =`${baseUrl}/user/auth/register`
export const VALIDATEUSERNAME =`${baseUrl}/user/auth/username`
export const LOGINUSER = `${baseUrl}/user/auth/login`
export const UPLOADPICTURE = `${baseUrl}/utils/upload`
export const UPLOADMULTIPLE = `${baseUrl}/utils/upload/multiple`
export const UPDATEPROFILE = `${baseUrl}/user/account/cooperate/profile` //token
export const GETPROFILEDATA = `${baseUrl}/user/account/profile`
export const SENDOTP = `${baseUrl}/user/auth/phone_number`
export const VALIDATEOTP = `${baseUrl}/user/auth/phone_number/verify`
export const GETCATEGORIES = `${baseUrl}/utils/categories`
export const GETSINGLEPRODUCT =(id)=> `${baseUrl}/products/fetch/${id}`

//products
export const ADDPRODUCT = `${baseUrl}/products`
export const GETALLPRODUCTS = `${baseUrl}/products/user`

//searchRequest
export const JOINREQUEST =(id)=> `${baseUrl}/smart-search/${id}/join`


//wallets
export const GETWALLET = `${baseUrl}/wallet`
export const GETWALLETHISTORY =(id)=> `${baseUrl}/transaction/${id}`


//memebers
export const GETALLMEMBERS = `${baseUrl}/members`
export const GETONEMEMBER=(id)=> `${baseUrl}/members/${id}`


//SOCKETS
export const USERPRODUCTREQUEST = 'user-product-request'
import React from 'react'
import { MenuContainer, 
        //  MenuIcon, 
        //  MenuItem, 
        //  MenuText 
        } from './style'
import {ReactComponent as Hamburger} from 'assets/svg/hamburger.svg'
// import HomeIcon from 'assets/svg/homeIcon.svg'
// import MemberIcon from 'assets/svg/membersIcon.svg'
// import { SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import BigMenu from './bigMenu'
import { useCorporateMenu } from 'context/corporateMenu'

export default function Menu() {
    const {corporateMenu, setCorporateMenu} = useCorporateMenu()
    function onActiveClick(name){      
        setCorporateMenu(()=>{
           const menu = corporateMenu.map(men=>{   
                if(name===men.text){
                    return(
                        {
                            ...men,
                            isActive: true
                        }
                    )
                }else{
                   return {
                            ...men,
                            isActive: false
                    }
                }
   
            })
            return [...menu]
        })
    }
    return (
        <MenuContainer>
            <Hamburger />
            {
                corporateMenu.map((menu,i)=>(
                    <BigMenu link={menu.link} 
                             name={menu.text} 
                             onActiveClick={onActiveClick} 
                             key={i} 
                             isActive={menu.isActive} 
                             icon={menu.isActive?menu.white:menu.icon} 
                             text={menu.text} />
                ))
            }
        </MenuContainer>
    )
}

// import GoogleMaps from 'components/dashboard/main/GoogleMap'
// import Map from 'components/dashboard/main/GoogleMap/map'
import LeftLayout from 'components/onboading/leftLayout'
import { useAuthContext } from 'context/AuthContext'
import React, { useEffect } from 'react'
import { DASHBOARD } from 'routes/routes'
import Form from './form'
import {LoginPageContainer} from './style'
// import { geolocated } from "react-geolocated";
// import { Geolocation } from 'components/dashboard/main/GoogleMap/geolocation';

export default function Login({history}) {
    const {user} = useAuthContext()
    
    useEffect(()=>{
        if(user.isAuth) history.push(DASHBOARD)
    },[user.isAuth, history])

    return (
        <LoginPageContainer>
            {console.log('his',user)}
            <LeftLayout buttonText = 'Sign Up' />
            <Form />
            {/* <Geolocation /> */}
        </LoginPageContainer>
    )
}

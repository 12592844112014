import mem1 from 'assets/mem/mem1.png'
import mem2 from 'assets/mem/mem2.png'
import mem3 from 'assets/mem/mem3.png'
import mem4 from 'assets/mem/mem4.png'
import mem5 from 'assets/mem/mem5.png'
import mem6 from 'assets/mem/mem6.png'
import mem7 from 'assets/mem/mem7.png'
import mem8 from 'assets/mem/mem8.png'
import mem9 from 'assets/mem/mem9.png'

export const headData = ['S/N',
                        'Photo',
                        'Name',
                        'Email',
                        'Username',
                        'Account No',
                        'Bank',
                        'Amount W',
                        'Ratings',
                        'Verification',
                        'Status',
                        'Date']


export const membersData =[
    {
        photo: mem1,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem2,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.2,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem3,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem4,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem5,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'Regular',
        status: 'Suspended',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem5,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Blacklisted',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem6,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem7,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem8,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
    {
        photo: mem9,
        name: '	Adewale Adedamola',
        email: 'testingall@gmail.com',
        username: '@abudulma...',
        account: '3099678981',
        bank: 'First Bank',
        amount: 'N28000',
        rating: 4.5,
        verificaiton: 'verified',
        status: 'Active',
        date: '	15-08-2020 10-35-54'
    },
]
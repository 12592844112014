import React from 'react'
import mark from 'assets/mark.png'
import dots from 'assets/svg/dots.svg'
import {TransDetailsDiv, DetailsHeader, DetailsSpread} from './style'
import Detail from './detail'

export default function TransactionDetails() {
    return (
        <TransDetailsDiv>
            <DetailsHeader>
                <div>
                    <img style={{width:'27px'}} src={mark} alt='sign' /> 
                    <p>Sent</p>
                </div>
                <img src={dots} alt='dort' />
            </DetailsHeader>
            <h2>N20,500</h2>
            <div style={{marginTop:'15px'}}>
                <Detail title='Recipients' value='Self' />
            </div>
            <DetailsSpread>
                <Detail title='Username' value='@adedamola456' />
                <Detail title='Transaction Fee' value='NGN 0' />
                <Detail title='Payment method' value='Self' />
            </DetailsSpread>
            <div style={{marginTop:'25px'}}>
                <Detail title='Transaction ID' value='tdg87wdgh7udh32bd3dh3b' />
            </div>
        </TransDetailsDiv>
    )
}

import React from 'react'
import AModal from '..'
import Checking from './checking'
import Delegate from './delegate'
import SendingRequest from './sending'
import SentRequest from './sentRequest'
import { AvailabilityDiv, ButtonArea, NextButton } from './style'
// import {useModalContext} from 'context/allModalContext'
import { useState } from 'react'
import { useAuthContext } from 'context/AuthContext'
import { JOINREQUEST } from 'routes/backend'

export default function Availability({modals, setModals,product,smartId}) {

    const {user} = useAuthContext();
    
    const [page, setPage] = useState(1)

    // function setRequestModal(){
    //     setModals({
    //         ...modals,
    //         requestModal: !modals.requestModal
    //     })
    // }

    async function joinRequest(){
        try{
        const response = await fetch(JOINREQUEST(user.smartId),{
                                    method: 'PUT',
                                    headers:{
                                        Authorization: `Bearer ${user.token}`
                                    }
                                })
        const data = await response.json()
        console.log('data',data)
        return data

        }catch(err){
            console.log(err.message)
        }
    }

    function closeRequestModal(){
        setModals({
            ...modals,
            requestModal: false
        })
    }

    function nextModal (){
        if(page<4){
            if(page === 2){
                setModals({
                    ...modals,
                    requestModal: false,
                    selectUserModal: true
                })
            }

            if (page===1){
                joinRequest()
            }
            setPage(page+1)
        }
    }

    // useEffect(()=>{
    //     // setRequestModal()


    // },[])
    return (
        <AvailabilityDiv>
           {/* {console.log(modals)} */}
            <AModal show={modals.requestModal} modalClosed={closeRequestModal}>
                {page===1?<Checking
                    text = {`Someone is searching for ${product} on Spottr. 
                    Is this product available?`}
                    headerText = 'Checking Availability'
                />:null}
                {page===2?<Delegate
                    headerText = {`Delegate Request to members?`}
                    text = {`Forward this search request to the memebers of your corporate lists?`}
                />:null}

                {page===3?<SendingRequest 
                    headerText= {`Sending`}
                    text= "Sending request 1/36"
                />:null}
                {page===4?<SentRequest 
                    headerText= 'Request Sent'
                    text = 'Request sent to the members of your corporation'
                />:null}
                <ButtonArea>
                    <NextButton style={{display:page===4?'none':''}} onClick={closeRequestModal}>No</NextButton>
                    <NextButton blue style={{width:page===4?'100%':'50%'}} onClick={nextModal} >{page===4?'Close':'Yes'}</NextButton>
                </ButtonArea>
            </AModal>
        </AvailabilityDiv>
    )
}

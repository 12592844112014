import React from 'react'
import { RatedDiv } from './style'

export default function Rated() {
    return (
        <RatedDiv>
           <p>4,567 people rated</p> 
        </RatedDiv>
    )
}

import React from 'react'
import { SingleButtonDiv } from './style'

export default function SingleButton({icon, title}) {
    return (
        <SingleButtonDiv>
           <img src={icon} alt='cion' /> 
           <p>{title}</p>
        </SingleButtonDiv>
    )
}

// import HomeIcon from 'assets/svg/homeIcon.svg'
import MemberIcon from 'assets/svg/membersIcon.svg'
import CustomerIcon from 'assets/svg/customerIcon.svg'
import RequestIcon from 'assets/svg/requestIcon.svg'
import Face from 'assets/svg/face.svg'
// import SettingIcon from 'assets/svg/settingIcon.svg'
import greyHomeIcon  from 'assets/svg/greyHome.svg'
// import whiteCustomer  from 'assets/svg/whiteCustomer.svg'
// import whiteMember  from 'assets/svg/whiteMember.svg'
// import whiteRequest  from 'assets/svg/whiteRequest.svg'


export const membersData = [
    {
        icon: greyHomeIcon,
        name: "Leanne Graham",
        username: "@Bret"
    },
    {
        icon: Face,
        name: "Ervin Howell",
        username: "@Antonette"
    },
    {
        icon: MemberIcon,
        name: "Clementine Bauch",
        username: "@Samantha"
    },
    {
        icon: CustomerIcon,
        name: "Patricia Lebsack",
        username: "@Patricia Lebsack"
    },
    {
        icon: greyHomeIcon,
        name: "Chelsey Dietrich",
        username: "@Kamren"
    },
    {
        icon: RequestIcon,
        name: "Leanne Graham",
        username: "@Bret"
    },
    {
        icon: greyHomeIcon,
        name: "Leanne Graham",
        username: "@Bret"
    },
    {
        icon: Face,
        name: "Ervin Howell",
        username: "@Antonette"
    },
    {
        icon: MemberIcon,
        name: "Clementine Bauch",
        username: "@Samantha"
    },
    {
        icon: CustomerIcon,
        name: "Patricia Lebsack",
        username: "@Patricia Lebsack"
    },
    {
        icon: greyHomeIcon,
        name: "Chelsey Dietrich",
        username: "@Kamren"
    },
    {
        icon: RequestIcon,
        name: "Leanne Graham",
        username: "@Bret"
    },
]
import InputField from 'components/onboading/inputField'
import React from 'react'
// import { PasswordText } from './style'
import PasswordStrengthBar from 'react-password-strength-bar';
import { useRegContext } from 'context/RegContext';

export default function PasswordForm({handleFormChange, setPasswordScore}) {
    const {regData} = useRegContext()
    
    return (
        <div style={{marginTop:'30px'}}>  
            <InputField value={regData.form.password} handleFormChange={handleFormChange} show='block' label='Create a password' name='password' placeholder = "Enter your password" type='password' required='true' />
            <PasswordStrengthBar minLength={6} onChangeScore={(score)=>setPasswordScore(score)} password={regData.form.password} />
            {/* <PasswordText>6 Characters, Minimum<br />
               Includes Number <br />
               Symbols <br />
               Capital Letter <br />
               Lower-Case Letter
            </PasswordText> */}
            {/* <Div
                display= 'flex'
                justify= 'center'
            > */}
                {/* <RegText>By entering your phone, you agree to the <SpottrLink to='/' color={BrandColors.brandColor}> Terms of Service & Privacy Policy </SpottrLink></RegText> */}
            {/* </Div> */}
        </div>
    )
}

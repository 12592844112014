import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'
import Colors from 'GlobalStyles/utils/appColors';


export const HeaderContainer = styled.div`
  // height: 150px;
  // width: 97%;
  // margin: 0 auto;
  border-bottom: 1px solid #C2E0FF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0px;

  ${media.tablet`
        display:none;
    `}
`;


export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const CorporateHeader = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #274B89;
`

export const CompanyName = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 4px 0 20px 0;
    color: #929AA7;
`

export const NotificationArea = styled.div`
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 220px;
`
export const NotificationDiv = styled.div`
display: flex;
justify-content: space-between;
width: 80px
`
export const NotificationIcon = styled.img`
    margin-right: 12px;
    cursor: pointer;
`
export const ImageDiv = styled.div`
    display: flex;

    img{
        cursor: pointer;
    }
`
export const ProfilePicture = styled.img`
  width: 40px;
  border: 2px solid #274B89;
  height: 40px;
  margin-right: 20px;
  border-radius: 50%;
`

export const HeaderMenuArea = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`
export const HeaderMenus = styled.div`
  display: flex;
  width: 520px;
  justify-content: space-between;
  // margin-left: -70px;
`
export const SpottrLogo = styled.img`
    width: 80px;
    // margin-right: 100px;
`
export const SingleMenuDiv = styled.div`
  // margin-right: 45px;
  margin-bottom:-10px;
  // width: 120%;
   .menu-active {
     border-bottom: 2px solid ${Colors.primary};
     color: ${Colors.primary}
   }
  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 55px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    color: #929aa7;
  }
`;

export const AddNewCategories = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 11.0995px;
  line-height: 17px;
  color: #274b89;
  padding: 5px 12px;
  border-radius: 5px;
  transition: color .3s ease-in-out;

  &:hover{
    background: hsla(218, 56%, 35%, 1);
    color: #fff
  }
`



export const MessageNotificationContainer = styled.div`
display: flex;
margin-left: 10px;
position: relative;
cursor: pointer;
`;

export const MessageCountIndicator = styled.div`
  position: absolute;
  height: 22px;
  width: 22px;
  background: red;
  right: -15px;
  top: -10px;
  border-radius: 100%;
  border: 3px solid white;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  color: white;
  font-family: Poppins;
  font-style: normal;
`

export const NotifIndicator = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  background: red;
  right: -12px;
  top: -10px;
  border-radius: 100%;
  border: 4px solid white;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  color: white;
  font-family: Poppins;
  font-style: normal;
`

import React from 'react'
import { BriefInfoDiv } from './style'

export default function BreifInfo() {
    return (
        <BriefInfoDiv>
            <h2><b>Brief Info:</b></h2>
            <p>
                I’ve worked directly, indirectly with these brands Either part time, 
                full time, campaign, accelerator, branding or product design.
            </p>
        </BriefInfoDiv>
    )
}

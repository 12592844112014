import React from 'react'
import { RestartDiv, ShowUploadsDiv, UploadDiv } from './style'

export default function ShowUploads({images,myUploads,restart}) {
    return (
        <ShowUploadsDiv>
            <RestartDiv>
                <p>{myUploads.length}/3</p>
                <h3 onClick={restart}>Restart</h3>
            </RestartDiv>
            <UploadDiv>
                {
                    images.map((image,i)=><img key={i} src={image} alt='upload' />)
                }
            </UploadDiv>
        </ShowUploadsDiv>
    )
}

import { Div } from 'GlobalStyles/spotrrStyles/style.js'
import React from 'react'
import {MetricsContainer} from './style.js'

export default function Metrics({text='search', number=456, percent='20%'}) {
    return (
        <MetricsContainer>
            <p>{text}</p>
            <h2>{number}</h2>
            <Div display='flex'>
                <small>{percent}</small>
            </Div>
        </MetricsContainer>
    )
}

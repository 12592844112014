import React, { createContext, useContext } from "react";
import { useSecureStorage } from 'Hooks/useSecureStorage'

const PaginationContext = createContext();

export default function Pagination({ children }) {
  

  const [paginationData, setPaginationData] = useSecureStorage("pagination",{
    currentPage: 1,
  });

  return (
    <PaginationContext.Provider value={{paginationData, setPaginationData}}>
      {children}
    </PaginationContext.Provider>
  );
}

export const usePagination= ()=> useContext(PaginationContext)

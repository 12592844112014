// import Pagination from 'components/dashboard/main/pagination'
import React, { useEffect, useState } from 'react'
import MembersHead from './head'
import { MembersDiv } from './style'
import Table from './table'
import { membersData } from './table/data'
import DistributionList from './distributionList'
import { useModalContext } from 'context/allModalContext'


export default function Members() {
    const [myInput, setMyInput] = useState('')
    const [result, setResult] = useState([])
    const {modals, setModals} = useModalContext()
    

    useEffect(()=>{
        const data = !myInput
                ? membersData
                : membersData.filter(
                    (data) =>
                        data.name.toLowerCase().includes(myInput.toLowerCase()) ||
                        data.email.toLowerCase().includes(myInput.toLowerCase()) ||
                        data.status.toLowerCase().includes(myInput.toLowerCase())  
                    )
                    setResult([...data])

    },[myInput])

    return (
        <MembersDiv>
            {/* <BroadcastMessage /> */}
            <MembersHead modals={modals} setModals={setModals} input={myInput} setInput={setMyInput} />
            {
                modals.distributionList?<DistributionList  />:<Table input={myInput} result={result} /> 
            }
            {/* <Pagination /> */}
            {/* <Pagination />  */}
        </MembersDiv>
    )
}

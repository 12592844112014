import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const PerformanceContainer = styled.div`
    display: flex ;
    min-width: 358px;
    min-height: 133px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    /* border: 1px solid black; */
    justify-content: space-between;
    padding:10px 40px 10px 30px;
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;

    ${media.tablet`
        min-width: 100%;
        max-width: 100%;
    `}
`

export const PerformanceTitle = styled.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13.5742px;
    line-height: 20px;
    color: #274B89;
`

export const PerformanceAds = styled.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11.0995px;
    line-height: 17px;
    color: #274B89; //green
`

export const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const ScoreDiv = styled.div`
    width: 85px;
    height: 85px;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: #274B89;
    }
`

export const BestDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom:3px;

  img{
    border-radius: 50%;
    margin-right: 15px;
    border: 3px solid #274B89;
    width: 37px;
  }

  p{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #000000;
  }

  small{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #C4C4C4;
  }
`

export const TagDiv = styled.div`
    min-width: 72px;
    height: 32px;
    display: flex;
    background: #FFFFFF;
    border: 0.803509px solid rgba(154, 159, 191, 0.25);
    box-sizing: border-box;
    border-radius: 4.01754px;
    align-items: center;
    justify-content: space-around;
    margin-right: 5px;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 7.1423px;
        line-height: 10px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #061E48;
    }
`

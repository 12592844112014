import styled from 'styled-components/macro'

export const BriefInfoDiv = styled.div`
    h2{
        font-family: Nunito;
        font-style: normal;
        font-weight: 900px;
        font-size: 13px;
        line-height: 158.8%;
        color: rgba(0, 0, 0, 0.7);
        margin-top: 5px;
    }
    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 158.8%;
        color: rgba(59, 59, 59, 0.7);
        margin-top: 15px;
    }
`
import React from 'react'
import { PaginationDiv, ViewAll, PaginatingArea, Pages } from './style'
import {useQuery} from 'react-query'
// import { getAllMembers } from './services'
import { usePagination } from 'context/Pagination'
import { useAuthContext } from 'context/AuthContext'
import { GETALLMEMBERS } from 'routes/backend'

export default function Pagination({endpoint,topic='members',page=1}) {

    const {user} = useAuthContext()

    async function getAllMembers(){
        const response = await fetch(`${GETALLMEMBERS}?page=1&limit=20`,{
            headers:{
                Authorization: `Bearer ${user.token}`
            }
        })
        const data = await response.json()
        console.log("getAllMEmebers",data.meta)
        return data;
    }
 
    //pagination not completed
    // const fetcher =  getAllMembers(1,user.token)  
    const { data} = useQuery([topic,page],getAllMembers)

    
    const {paginationData} = usePagination()
    return (
        <PaginationDiv>
            {console.log("pagination",paginationData)}
            {console.log("paginationData",data)}
            {console.log("page",page)}
            <ViewAll>View All</ViewAll>
            <PaginatingArea>
                <p>Prev</p>
                <Pages>
                    <p >1</p>
                    <p className="active">2</p>
                    <p>3</p>
                </Pages>
                <p>Next</p>
            </PaginatingArea>
        </PaginationDiv>
    )
}

import styled from 'styled-components/macro'

export const SingleRequestDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    border-bottom: 1px solid #E7E7E7;
    border-top: 1px solid #E7E7E7;
    /* padding-bottom:3px; */
    padding: 10px;
    margin-right: 10px;

  img{
    border-radius: 50%;
    margin-right: 15px;
    border: 3px solid #274B89;
    width: 37px;
    height: 37px;
  }

  p{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #000000;
  }

  small{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #C4C4C4;
  }

`

export const ActionDiv = styled.div`
    width: 182px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 0px 2.3038px 11.519px rgba(0, 0, 0, 0.07);
    border-radius: 41.4683px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`

export const CheckDiv = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    img{
    border-radius: 0%;
    margin-right: 0px;
    border: none;
    width: 12px;
    height: 11px;
    margin-right: 3px;
  }

  p{
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 7.48734px;
    line-height: 150%;
    color: #39B54A
  }
  small{
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 7.48734px;
    line-height: 150%;
    color: #FF4B3E
  }
  
`
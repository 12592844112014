import React from 'react'
import HistoryDetails from './HistoryDetails'
import { HistoryDiv } from './style'
import WalletBox from './WalletBox'

export default function History({icon,walletName,walletAmount,currency,transactions}) {
    return (
        <HistoryDiv>
            <WalletBox currency={currency} icon={icon} walletName={walletName} walletAmount={walletAmount} />
            {/* {
                transactions.map(()=><HistoryDetails />)
            }           */}
            <HistoryDetails />
        </HistoryDiv>
    )
}

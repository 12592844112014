import LadyPic from 'assets/svg/lady.svg'

export const bestRatingData = [
    {
        name: 'Sarah Johnson',
        location: 'Lagos, Nigeria',
        bg: 'yellow',
        image: LadyPic,
        total: '5768',
        rating: 4.4
    },
    {
        name: 'John Tommy',
        location: 'Rivers, Nigeria',
        bg: 'blue',
        image: LadyPic,
        total: '3461',
        rating: 4.9
    },
    {
        name: 'John Tommy',
        location: 'Rivers, Nigeria',
        bg: 'blue',
        image: LadyPic,
        total: '3461',
        rating: 4.1
    },
]
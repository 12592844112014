import React, { useState } from 'react'
import ChooseWallet from './chooseWallet'
import PaymentMethod from './paymentMethod'
import { WithdrawalContainer } from './style'
import WithdrawalAmount from './withdrawalAmount'


export default function Withdraw() {
    const [page, setPage] = useState(1)

    function changePage(){
        setPage(page + 1)
    }

    return (
        <WithdrawalContainer>
            {page===1?<WithdrawalAmount setPage={changePage} />:null}
            {page===2?<ChooseWallet setPage={changePage} />:null}
            {page===3?<PaymentMethod setPage={setPage} /> : null}
        </WithdrawalContainer>
    )
}

import { useRegContext } from 'context/RegContext'
import React from 'react'
import 'react-phone-input-2/lib/style.css'
import { PhoneField } from './style';

export default function Phone() {
    const {regData, setRegData} = useRegContext()

    const onChangePhone = (phoneNumber) => {
        setRegData({
          ...regData,
          form:{
            ...regData.form,
            phoneNumber
          }
        });
      };
    return (
        <>
           <PhoneField
                //   key={index}
                  country={"ng"}
                  onChange={(phoneNumber) => `+${onChangePhone(phoneNumber)}`}
                  placeholder= 'Phone Number'
                  value={regData.form.phoneNumber}
                  inputStyle={{
                      width: '100%',
                      height: '45px',
                      backgroundColor: "#f0f0f0",
                      border: 'none'
                  }}
                />
        </>
    )
}

import React, { useState, useEffect } from 'react'
// import TopSellers from 'components/dashboard/main/topSellers'
// import BestRating from 'components/dashboard/main/bestRating'
// import GoogleMaps from 'components/dashboard/main/GoogleMap'
// import MapBox from 'components/dashboard/main/GoogleMap/mapBox'
// import Map from 'components/dashboard/main/GoogleMap/map'
// import { usePosition } from 'Hooks/usePosition'
import Sidebar from 'components/dashboard/main/Sidebar'
import Menu from 'components/dashboard/main/menu'
import { DashboardBody, DashboardContainer } from './style'
import DashboardHeader from './dashboardHeader/dashboard'
// import DashboardHome from './home/DashboardHome'
import { Switch } from 'react-router-dom'
import { dashboardRoutes } from './routes'
import MobileMenu from 'components/dashboard/main/menu/mobileMenu'
import AddProduct from '../addProduct'
import ProtectedRoute from 'utilities/ProtectedRoute'
import { useAuthContext } from 'context/AuthContext'
import CorporateMenu from 'context/corporateMenu'
import axios from 'axios'
import { baseUrl, GETPROFILEDATA, USERPRODUCTREQUEST } from 'routes/backend'
import {useHistory} from 'react-router-dom'
import Availability from './home/AvailabilityModal/requests'
import { useModalContext } from 'context/allModalContext'
import io from 'socket.io-client'
// import asyncFetch from 'utilities/asyncFetch'

export default function Dashboard() {
    const [open, setOpen] = useState(false)
    const [productRequest, setProductRequest] = useState('')
    const {user, setUser} = useAuthContext()
    const { modals, setModals } = useModalContext()
    const history = useHistory()
    const {data,token} = user

    if (!user.isAuth)history.push('/')


    // function requestProduct(){
    //     const testData = {
    //         productName: 'pizza',
    //         lat: 4.8184381,
    //         lng: 6.962205699999999,
    //         type: 'simple'
    //       }
    //     asyncFetch('post',token,testData,baseUrl+'/smart-search')
    //        .then(data=>{
    //            console.log("prodDaat",data)
    //            setUser({
    //                ...user,
    //                smartId: data.data._id
    //            })
    //         })
    //        .catch(err=>console.log('err Data', err))
    // }

    useEffect(()=>{      
        axios.get(GETPROFILEDATA,{
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
        .then(res=>{
            const {data} = res.data
            setUser((prev)=>{
                    return{
                    ...prev,
                    data:{
                        ...prev.data,
                        ...data
                    }
            }})
        },[])
        .catch(err=>{
            console.log('error',err.response)
            console.log(err)
        })

       
        const socket = io(baseUrl, { query: {token}});
        console.log("sockets",socket)


        socket.on(USERPRODUCTREQUEST, (data) => {
            console.log('socket response is ', data);
            setProductRequest(data.productName)
            setModals({
                        ...modals,
                        requestModal: !modals.requestModal
                    })
            });

        socket.on('smart-search', (data) => {
        console.log('updated data is ', data);
        });

        

    },[setUser, token, modals, setModals])

    

    return (
        <DashboardContainer>
            <Availability modals={modals} setModals={setModals} product={productRequest} />
            <MobileMenu open={open} setOpen={setOpen} />
            <CorporateMenu>
            <Menu />
            <Sidebar open={open} setOpen={setOpen} />
            </CorporateMenu>
            <AddProduct />
            <DashboardBody>
            {/* <button onClick={requestProduct}>Connect Socket</button> */}
                <DashboardHeader company={data?.brandName} />
                <Switch>
                    {
                        dashboardRoutes.map((route,i)=>(
                            <ProtectedRoute key={i} exact={route.exact} path={route.path} component={route.component} />  
                        ))
                    }
                </Switch>
            </DashboardBody>
            {/* <TopSellers />
            <BestRating /> */} 
            {/* {console.clear()}  */}
        </DashboardContainer>
    )
}

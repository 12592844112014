import React from 'react'
import BlueBox from './BlueBox'
import { AmountArea, 
         ButtonArea, 
         HistoryArea, 
         OptionsContainer, 
         StatementArea, 
         WalletsContainer, 
         WalletText, 
         WalletTextDiv, 
         WalletTop } from './style'
import WalletButton from './WalletButton'
import withdraw from 'assets/svg/withdraw.svg'
import buyCoins from 'assets/svg/buy.svg'
import Options from './options/Options'
import { useAuthContext } from 'context/AuthContext'
import {GETWALLET} from 'routes/backend'
import History from './history'
import fiat from 'assets/fiat.svg'
import cliq from 'assets/cliq.svg'
import susd from 'assets/susd.svg'
import {ReactComponent as Spinner} from 'assets/svg/spinner.svg'
import WalletContext from 'context/wallet'
// import 'reactjs-popup/dist/index.css';
import {useQuery} from 'react-query'
import Withdraw from './withdraw'
import BuyCoins from './withdraw/buycoins'

export default function Wallets() {
    const {user} = useAuthContext()
    // const [wallets,setWallets] = useState([])
    // const [mount] = useState(0)

    const memoizedOptions = {
        method: 'get',
        headers:{
            Authorization: `Bearer ${user.token}`
        }
    }
    console.log('Token abeg', user.token)
    async function getWallets(){
        try{
            const response = await fetch(GETWALLET, memoizedOptions)
            const data = await response.json()
            console.log(data)
            return data
        }catch(err){
            return err.message
        }
    }

    const {isLoading, data, error} = useQuery("getWallets",  getWallets )

    console.log('====================================');
    console.log(data);
    console.log('====================================');

    return (
        <WalletContext>
       <WalletTextDiv>
        <WalletText>Wallets</WalletText>
       </WalletTextDiv>

        <WalletsContainer>
            <WalletTop>
                <AmountArea>
                    <BlueBox />
                    <ButtonArea>
                        <WalletButton icon={withdraw} text='Withdraw' >
                            <Withdraw />
                        </WalletButton>
                        <WalletButton icon={buyCoins} text='Buy coins'>
                            <BuyCoins />
                        </WalletButton>
                    </ButtonArea>
                </AmountArea>
                <StatementArea>
                    <OptionsContainer>
                        <Options text='Display QR code' />
                        <Options text='Open Loan log' />                      
                    </OptionsContainer>
                    <OptionsContainer>
                        <Options text='Generate Statement' />
                        <Options text='See all trasnaction activity' />                      
                    </OptionsContainer>
                </StatementArea>
            </WalletTop>
            <HistoryArea>
                {
                   isLoading? <Spinner />:!error?
                   <>
                   <History icon={fiat} currency='NGN' transactions={data.data['0'].transactions} walletName={data.data[0].name} walletAmount={data.data[0].balance} />
                   <History icon={cliq} currency='NGN' transactions={data.data[1].transactions} walletName={data.data[1].name} walletAmount={data.data[1].balance} />
                   <History icon={susd} currency='NGN' transactions={data.data[2].transactions} walletName={data.data[2].name} walletAmount={data.data[2].balance} />                  
                   </>:<Spinner />
                }              
            </HistoryArea>
        </WalletsContainer>
        </WalletContext>
    )
}

import styled from 'styled-components/macro'

export const PaymentMethodDiv = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
`

export const PaymentChoiceDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 13px;
    margin-top: 19px;

    div{
        display: flex;
        align-items: center;
        

        img{
            margin-right: 10px;
        }

        h2{
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #274B89;
            margin: auto 0;
        }
    }
`
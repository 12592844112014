import media from 'GlobalStyles/utils/media'
import PhoneInput from 'react-phone-input-2'
import styled from 'styled-components/macro'

export const RegContainer = styled.div`
    width: 50vw;
    /* margin: 30px 80px; */
    margin-left: 54vw;
    padding: 50px;

    ${media.tablet`
        width: 100%;
        margin-left: 0;
    `}
    ${media.mobile`
        width: 100%;
        margin: 20px 25px;
        padding: 0;
    `}
`

export const JoinPageContainer = styled.div`
    width: 100vw;
    max-height: 100vh;
    overflow: auto;
    display: flex;
`

export const BackText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #274B89;
    margin-left: 10px;
    cursor: pointer;
`

export const RegText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 158.8%;
    text-align: center;
    color: #000000;
    width: 250px;
`
export const SignupForm = styled.div`
    display: flex;
    flex-direction: column;
`
export const PasswordText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 158.8%;
    margin-bottom: 20px;
    /* or 22px */


    color: #000000;
`

export const PhoneField = styled(PhoneInput)`
  border: 3px solid red;
  width: 100%;
  padding: 0.5rem;

  .selected-flag{
      
  }
`;
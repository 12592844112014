import React from 'react'
import { ActivityDiv } from './style'

export default function Activity() {
    return (
        <ActivityDiv>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'10px'}}>
                <h3>SUSD Purchased</h3>
                <p>Aug 21</p>
            </div>
            <p>User purchased $567 from spottr ref: <a href='https://spottradmin.8783/uynbunbjahn'>https://spottradmin.8783/uynbunbjahn</a></p>
        </ActivityDiv>
    )
}

import { Button, Div, Img } from 'GlobalStyles/spotrrStyles/style'
import React from 'react'
import AuthPageTopics from './authPageTopics'
import ForwardArrow from 'assets/svg/arrow-forward.svg'
import {topicData} from './topicData'
import { CircleContainer, 
         InnerCircle, 
         LeftLayoutContainer, 
         SpottrText, 
         WelcomeText } from './style'
import {SpottrLink} from 'GlobalStyles/spotrrStyles/style'
import { LOGIN, SIGNUP } from 'routes/routes'

export default function LeftLayout({buttonText}) {
    return (
        <LeftLayoutContainer>
            <CircleContainer>
                <InnerCircle />
            </CircleContainer>
            <Div>
                <WelcomeText>Welcome to</WelcomeText>
                <SpottrText>SpottR</SpottrText>
            </Div>
            <Div display= 'flex' wrap= 'wrap' width= '100%' justify= 'center' >
                {
                    topicData.map((topic,i)=><AuthPageTopics key={i} topic = {topic.topic} icon = {topic.icon} text = {topic.text} /> )
                }
            </Div>
            <Div display= 'flex' alignI= 'center'>
                <p>Open your own account</p>
                <Img src={ForwardArrow} alt='arrow' />
                <SpottrLink to={buttonText.toLowerCase()==='login'?LOGIN:SIGNUP}>
                <Button br="20px" w="90px" color="#274B89" p="10px" m='0 15px' >
                    {buttonText}
                </Button>
                </SpottrLink>
            </Div>
        </LeftLayoutContainer>
    )
}

import React, { useEffect } from 'react'
import LeftLayout from 'components/dashboard/welcome/leftLayout'
import { WelcomeHomePageDiv, WelcomeRightLayout } from './style'
import SetIcon from 'assets/svg/setIcon.svg'
import { Div } from 'GlobalStyles/spotrrStyles/style'
import ShortProfile from 'components/onboading/shortProfile'

export default function WelcomeHomePage({history}) {

    useEffect(()=>{
        setTimeout(()=>{
            history.push('/welcome')
        },4000)
    })
    return (
        <WelcomeHomePageDiv>
            <LeftLayout />
            <WelcomeRightLayout>
                <Div display='flex'>
                    <img src={SetIcon} alt='icon' />
                    <p>All Set!</p>
                </Div>
                <h2>Welcome</h2>
                <ShortProfile />
                <br />
                <br />
                <br />
                <p>Loading Page....</p>
            </WelcomeRightLayout>
        </WelcomeHomePageDiv>
    )
}

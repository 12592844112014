import React from 'react'
import { FiltersDiv } from './style'

export default function Filters({title,options}) {
    return (
        <FiltersDiv>
            <p>{title}</p>
            <select>
                {
                    options.map((option,i)=>(
                        <option key={i}>{option}</option>
                    ))
                }
            </select>
        </FiltersDiv>
    )
}

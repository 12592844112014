import styled from 'styled-components/macro'

export const RequestsDiv = styled.div`

`

export const AllRequestDiv = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const ActivitiesDiv = styled.div`
    width: 60%;
    min-height: 100%;
    border-right: 2px solid #c4c4c4;

    ${media.tablet`
        width: 100%;
    `}
`

export const ActivityDiv = styled.div`
    height: 57px;
    border-bottom: 2px solid #ececec;
    padding: 10px 10px 10px 0;
    h3{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
    }

    ${media.tablet`
        height: auto;
    `}
`
import { Div } from 'GlobalStyles/spotrrStyles/style'
import React from 'react'
import Filters from './filters'
import { MembersHeadDiv, MHeadText, MSearch } from './style'

export default function MembersHead({input,setInput,modals, setModals}) {
    function showMembers(){
        setModals({
            ...modals, 
            distributionList:false
        })
    }
    function showDistributionList(){
        setModals({
            ...modals,
            distributionList:true
        })
    }
    return (
        <MembersHeadDiv>
            <MHeadText onClick={showMembers} > Members </MHeadText>
            <MHeadText onClick={showDistributionList} >Distribution List</MHeadText>
            <MSearch onChange={(e)=>setInput(e.target.value)} placeholder='Search Email' />
            <Div display='flex' w='100%'>
                <Filters title='select' options={['money','monkey'] }/>
                <Filters title='filter by' options={['Name','Location', 'Rating', 'List'] }/>
            </Div>
        </MembersHeadDiv>
    )
}

import React from 'react'
import { WalletButtonDiv } from './style'
import { WithdrawalPopUp } from './withdraw/style'

export default function WalletButton({icon, text,children}) {
    return (
        <WithdrawalPopUp
            trigger={<WalletButtonDiv>
                        <img src={icon} alt={text} />
                        <p>{text}</p>
                    </WalletButtonDiv>}
            modal
        >
        {children}
        </WithdrawalPopUp>
    )
}

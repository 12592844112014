import React, { useState } from 'react'
import { WithdrawalAmountDiv } from './style'
import {ReactComponent as Down} from 'assets/svg/blackDown.svg'

export default function WithdrawalAmount({setPage}) {
    const [amount, setAmount] = useState(0)
    return (
        <WithdrawalAmountDiv>
            <h2>Withdraw funds</h2>
            <h1>input Amount to withdraw</h1>
            <div>
                <input value={amount} type='number' /> <p>NGN<Down /></p>
            </div>
            <button onClick={setPage} setAmount={setAmount}>Continue</button>
        </WithdrawalAmountDiv>
    )
}

import React from 'react'
import { ScoreDiv } from './style'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function Score({score=30}) {
    return (
        <ScoreDiv>
            <CircularProgressbarWithChildren value={score}>
                <p>{score}%</p>
            </CircularProgressbarWithChildren>
        </ScoreDiv>
    )
}

import React from 'react'
import Score from './Score'
import { PerformanceAds, PerformanceContainer, PerformanceTitle, TextDiv } from './style'



export default function Performance({title='performances', percentage=0, adsRunning=45}) {
    return (
        <PerformanceContainer>
            <TextDiv>
                <PerformanceTitle>{title}</PerformanceTitle>
                <PerformanceAds> <span style={{color:'#39B54A'}}>{adsRunning}</span> new ads running &gt; </PerformanceAds>
            </TextDiv>
            <Score score={percentage} />
        </PerformanceContainer>
    )
}

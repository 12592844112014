import Sellers from 'assets/svg/sellers.svg'
import Rating from 'assets/svg/rating.svg'
import Trending from 'assets/svg/trending.svg'
import Leaderboard from 'assets/svg/leaderboard.svg'

export const performanceData = [
    {
        title: 'Performances',
        percentage: 0,
        adsRunning: 0
    },
    {
        title: 'Search Responses',
        percentage: 0,
        adsRunning: 0
    }
]

export const bestPerformanceData = [
    {},
    {},
    {},
    {}
]

export const topSearchData = [
    {
        icon: Sellers,
        title: 'Top Sellers'
    },
    {
        icon: Rating,
        title: 'Best Rating'
    },
    {
        icon: Trending,
        title: 'Trending'
    },
    {
        icon: Leaderboard,
        title: 'Leaderboard'
    },
]
import React from 'react'
import { HeaderDiv } from '../chooseWallet/style'
import PaymentChoice from './paymentChoice'
import { PaymentMethodDiv } from './style'
import crypto from 'assets/svg/crypto.svg'
import bank from 'assets/svg/bank.svg'

export default function PaymentMethod({setPage}) {
    return (
        <PaymentMethodDiv>
            <HeaderDiv>
                <p>Select Wallet to withdraw from</p>
                <p style={{fontWeight:'bold', margin:'0px'}}>Back</p>
            </HeaderDiv>
            <PaymentChoice setPage={setPage} />
            <PaymentChoice setPage={setPage} name='Pay with crypto' fee='1.2% charge' icon={crypto} />
            <PaymentChoice setPage={setPage} name='Bank Transfer' fee='N52 charge' icon={bank} />
        </PaymentMethodDiv>
    )
}

import React from 'react'
import {UserDiv, Checker, Facer, CheckBoxDiv} from './style'
import checker from 'assets/svg/checked.svg'
import { Div } from 'GlobalStyles/spotrrStyles/style'
import Checkbox from './checkbox'



export default function User({setAllMembers, data, allMembers, index}) {
    function checkMember(){
        // console.log("isCheced",data.isChecked)
        const tempMembers = [...allMembers]
        const memberObj = tempMembers[index]
        memberObj.isChecked = !memberObj.isChecked
        setAllMembers(tempMembers)
        // console.log('allz',allMembers)
    }


    return (
        <UserDiv>
            <CheckBoxDiv type='checkbox' onClick={checkMember} style={{}} >
                <Checker display={String(allMembers[index]?.isChecked)} src={checker} alt='checks' />
                <Checkbox isChecked={allMembers[index]?.isChecked} />
            </CheckBoxDiv>
            <Div display='flex' style={{alignItems:'center'}}>
                <Facer src={data.icon} alt='looks' />
                <div>
                    <p>{data.name}</p>
                    <small>{data.username}</small>
                </div>
            </Div>
        </UserDiv>
    )
}

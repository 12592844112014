import React from 'react'

import { 
         LeftLayoutContainer, 
        } from './style'

export default function LeftLayout() {
    return (
        <LeftLayoutContainer>
            
        </LeftLayoutContainer>
    )
}

import React, { useState } from 'react'
import DummyPic from 'assets/svg/dummyPic.svg'
import InputField from 'components/onboading/inputField'
import { CompanyDetailsContainer, CompanyForm, UploadImageDiv } from './style'
import { VALIDATEUSERNAME, UPLOADPICTURE } from 'routes/backend'
import { useCorporateUser } from 'context/userDetails'
// import asyncFetch from 'utilities/asyncFetch'
// import axios from 'axios'


export default function CompanyDetails() {

    const [fileSrc, setFileSrc] = useState()
    const [fileName, setFileName] = useState()
    const {corporateUser, setCorporateUser} = useCorporateUser()

    function handleFormChange(e){
        setCorporateUser({
            ...corporateUser,
            data:{
                ...corporateUser.data,
                [e.target.name]: e.target.value
            }
        })
    }
    
    async function handleFile(e){  
 
        const file = await URL.createObjectURL(e.target.files[0]);
        const myFile = await e.target.files[0]
        setFileSrc(file)
        setFileName(myFile.name)
        console.log(myFile)
    
     
      // Update the formData object
      let formdata = new FormData();
      formdata.append("file", myFile, file);
      
      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(UPLOADPICTURE, requestOptions)
        .then(response => response.json())
        .then(res => {
            console.log(res)
            if(res.status === true){
                setCorporateUser({
                    ...corporateUser,
                    data:{
                        ...corporateUser.data,
                        profileUrl: res.data.url
                    }
                })
            }
        })
        .catch(error => console.log('error', error))
    }

    function handleUsername(){
        console.log('bur',corporateUser.token)
        //   axios.post(VALIDATEUSERNAME,{
        //       body:{
        //           username:'shawn'
        //       },
        //       headers:{
        //         Authorization: `bearer ${corporateUser.token}`
        //       }
        //   }).then(res=>console.log(res))
        //     .catch(err=>console.log('useError',err.response))

        fetch(VALIDATEUSERNAME,{
            method: 'post',
            body: {
                username: 'shawn'
            },
            headers:{
                Authorization: `bearer ${corporateUser.token}`
            }
        }).then(res=>res.json())
          .then(data=>console.log('resp',data))
          .catch(err=>console.log('errs',err.response))

        // asyncFetch('POST',corporateUser.token,'shawn',VALIDATEUSERNAME)
        //     .then(data=>console.log("user",data))
        //     .catch(err=>console.log('errpr',err.response))

    }
    return (
        <CompanyDetailsContainer>
            {console.log('otherBrand',corporateUser)}
            <UploadImageDiv>
                <img src={corporateUser.data.profileUrl || fileSrc || DummyPic} alt='proPic' />
                <p>Upload new photo</p>
                <p>{fileName}</p>
                <input onChange={handleFile} type='file' />
            </UploadImageDiv>
            <CompanyForm>
                <p style={{color:'black'}}>company details</p>
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.brandName || ''} type='text' displayEye='false' name='brandName' placeholder='Brand Name' />
                <InputField handleFormChange={handleFormChange} onBlur={handleUsername} value={corporateUser.data.username || ''} type='text' displayEye='false' name='username' placeholder='Choose a username' />
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.contactEmail || ''} type='text' displayEye='false' name='contactEmail' placeholder='Contact Mail' />
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.contactPhoneNumber || ''} type='text' displayEye='false' name='contactPhoneNumber' placeholder='Contact Phone Number' />
            </CompanyForm>
        </CompanyDetailsContainer>
    )
}

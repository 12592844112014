import styled from 'styled-components/macro'

export const CustomersDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    /* p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #274B89;
    } */
`

export const RegUsers = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #274B89;
`

export const AllCustomers = styled.div`
    width: 100%;
    margin-top: 20px;
    /* height: 100%; */
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
`

export const EmptyDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 80px;

    h3{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #061E48;
        margin-top: 10px;
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #061E48;
    }

    button{
        width: 200px;
        padding: 13px;
        background: #274B89;
        box-shadow: 0px 10.1752px 22.6117px rgba(171, 180, 189, 0.148862);
        border-radius: 4.52233px;
        color: white;
        border: none;
        margin-top: 10px;
        cursor: pointer;
    }
`
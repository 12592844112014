import LeftLayout from 'components/dashboard/welcome/leftLayout'
import { SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import { LoginButton } from 'pages/Login/style'
import React, { useRef, useState } from 'react'
import CompanyDetails from './companyDetails'
import { WelcomePageContainer, BackDiv, FormArea, AlertDiv, BackArea } from './style'
import Alert from 'assets/svg/alertCircle.svg'
import backArrow from 'assets/svg/backArrow.svg'
import { BackText } from 'pages/register/style'
import LocationForm from './locationForm'
import DescribeCompany from './describeCompany'
import { DASHBOARD } from 'routes/routes'
import { useCorporateUser } from 'context/userDetails'
import asyncFetch from 'utilities/asyncFetch'
import { UPDATEPROFILE } from 'routes/backend'
// import { useRegContext } from 'context/RegContext'
import Toast from 'components/UI/Toast'
import { useAuthContext } from 'context/AuthContext'
// import { UPLOADPICTURE } from 'routes/backend'


export default function Onboarding({history}) {
    const {corporateUser, setCorporateUser} = useCorporateUser()
    const [errMessage, setErrMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const btnRef = useRef('')
    const {welcomePage} = corporateUser
    const {user, setUser} = useAuthContext()


    // asyncFetch(method, token=null, data=null, endpoint)
    function updateProfile(){
        const {token} = corporateUser
        btnRef.current.textContent = 'loading...'
        asyncFetch('POST',token,corporateUser.data, UPDATEPROFILE)
            .then(data=>{
                btnRef.current.textContent = 'Submit'
                console.log(data)
                if(data.status=== false){
                    setErrMessage(data.message)
                    setShowModal(true)
                    return;
                }
                if(data.status === true){
                    setUser({
                        ...user,
                        isAuth: true
                    })
                    window.location.href = DASHBOARD
                }
            })
            .catch(err=>console.log(err))
    }

    function onNext(e){
        e.preventDefault();
        if(welcomePage===2){
            updateProfile()
            return 
            // history.push(WELCOME)
        }
        setCorporateUser({
            ...corporateUser,
            welcomePage: corporateUser.welcomePage + 1
        });
    }
    function onPrev(){
        if(welcomePage > 0)
        setCorporateUser({
            ...corporateUser,
            welcomePage: corporateUser.welcomePage - 1
        });
    }
    return (
        <WelcomePageContainer>
            {console.log('coop',corporateUser)}
            <Toast icon='error' description={errMessage} showModal={showModal} setShowModal={setShowModal} />
           <LeftLayout /> 
           <FormArea >
            
           <BackArea display='flex' alignI='flex-start' justify='space-between'>
                <BackDiv onClick={onPrev} >
                    <img src={backArrow} alt='' />
                    <BackText>Go Back</BackText>
                </BackDiv>
                <SpottrLink to='/welcome'><p>I'll do this later</p></SpottrLink>
            </BackArea> 
           {welcomePage===0? <CompanyDetails 
                                corporateUser={corporateUser} 
                                setCorporateUser={setCorporateUser} />: null}
           {welcomePage===1? <LocationForm corporateUser={corporateUser} setCorporateUser={setCorporateUser} />: null}
           {welcomePage===2? <DescribeCompany corporateUser={corporateUser} setCorporateUser={setCorporateUser} />: null}
           <AlertDiv>
                <img src={Alert} alt='alert' />
                <p>
                Type in your correct name and use a good photo, make 
                sure it is clear so that it will increases your chance
                 of getting good gigs :)
                </p>
           </AlertDiv>
           <LoginButton ref={btnRef} style={{width:'80%'}}onClick={onNext}>{welcomePage===2?'Submit':'Continue'}</LoginButton>
           </FormArea>
        </WelcomePageContainer>
    )
}

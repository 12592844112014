import React from 'react'
import { SharedDiv, SharedText } from './style'

export default function Share({title='Share Abdul\'s Profile',color}) {
    return (
        <SharedDiv>
            <SharedText color={color}>{title}</SharedText>
            <SharedText color={color}>&#62;</SharedText>
        </SharedDiv>
    )
}

import styled from 'styled-components/macro'

export const ChooseWalletContainer = styled.div`
    display: flex;
    flex-direction: column;

   
    h1{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #274B89;
        margin-top: 20px;
        margin-left: 20px;
    }

    div{
        display: flex;
        align-items: center;

        p{
            font-size: 13px;
        }
    }

`

export const HeaderDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
    margin-top: 20px !important;
    margin-bottom: 40px !important;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #274B89;
    }
`

export const PriceDiv = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    h2{
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: right;
        color: #274B89;
    }

    h3{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #274B89;
        margin-left: 20px;
    }

    img{
        margin-right: 10px;
        width: 20px;
        margin-bottom: -5px;
    }

`

export const WalletInfoDiv = styled.div`
    display: block !important;
    flex-direction: column;
    width: 100%;
    /* height: 40px; */
    border-bottom: 1px solid #E7E7E7;
    border-bottom-width: 20%;
    justify-content: space-between;
    align-items: space-between;
    margin-bottom: 15px;
    padding-bottom: 5px;
    
`

export const CTtext = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 9px !important;
    line-height: 12px;
    text-align: right;
    color: #5E7398;
`

export const Cancel = styled.p`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #274B89;
    text-align: center;
    position: relative;
    top: 15px;
`
import styled from 'styled-components/macro';

export const SoldDiv = styled.div`
    width: 83px;
    height: 23px;
    background: #E3E8F0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* margin: 10px 0; */

    img{
        width: 8px;
        height: 8px;
        margin-left: 3px;
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
        text-align: center;
        color: #274B89;
        margin-left: 4px;
    }
`
import SingleProduct from "../addProduct/singleProduct";
import Customers from "./customers";
import Members from "./member";
import SingleMemeber from "./member/singleMember";
import Products from "./products";
import Requests from "./requests";
import { SINGLEMEMBER,
         ALLMEMBERS,
         DASHBOARDHOME, 
         CUSTOMERS,
         REQUESTS,
         WALLETS,
         SINGLEPRODUCT,
         PRODUCTS,
         SETTINGS} from "./ROUTECONST";
import Settings from "./settings";
import Wallets from "./wallets";

const { default: DashboardHome } = require("./home/DashboardHome");

export const dashboardRoutes = [
    {
        path: DASHBOARDHOME,
        component: DashboardHome,
        exact: true,
        protected: true,
    },
    {
        path: ALLMEMBERS,
        component: Members,
        exact: true,
        protected: true,
    },
    {
        path: SINGLEMEMBER,
        component: SingleMemeber,
        exact: true,
        protected: true,
    },
    {
        path: SINGLEPRODUCT,
        component: SingleProduct,
        exact: true,
        protected: true,
    },
    {
        path: CUSTOMERS,
        component: Customers,
        exact: true,
        protected: true,
    },
    {
        path: PRODUCTS,
        component: Products,
        exact: true,
        protected: true,
    },
    {
        path: REQUESTS,
        component: Requests,
        exact: true,
        protected: true,
    },
    {
        path: SETTINGS,
        component: Settings,
        exact: true,
        protected: true,
    },
    {
        path: WALLETS,
        component: Wallets,
        exact: true,
        protected: true,
    },
    
]
import React from 'react'
import { Cancel, ChooseWalletContainer, HeaderDiv } from './style'
import fiat from 'assets/fiat.svg'
import cliq from 'assets/cliq.svg'
import susd from 'assets/susd.svg'
import blueDown from 'assets/svg/blueDown.svg'
import WalletInfo from './wallet'

export default function ChooseWallet({ name='Fiat Wallet', currency='N', amount='234,123', CTvalue='350.87 CT', setPage}) {
    return (
        <ChooseWalletContainer>
            <HeaderDiv>
                <p>Select Wallet to withdraw from</p>
                <p>NGN <img src={blueDown} alt='arr' /></p>
            </HeaderDiv>
            <WalletInfo setPage={setPage} icon={fiat} name={name} currency={currency} amount={amount} CTvalue={CTvalue} />
            <WalletInfo setPage={setPage} icon={cliq} name={`Cliq Token`} currency={currency} amount={amount} CTvalue={CTvalue} />
            <WalletInfo setPage={setPage} icon={susd} name={`SUSD`} currency={currency} amount={amount} CTvalue={CTvalue} />
            <Cancel>Cancel</Cancel>
        </ChooseWalletContainer>
    )
}

import React from 'react'
import CustomersHead from './head'
import {
    CustomersDiv,
    AllCustomers,
    RegUsers,
    EmptyDiv} from './style'
import Customer from './customer'
import empty from 'assets/svg/emptyMember.svg'
import Pagination from 'components/dashboard/main/pagination'

export default function Customers() {
    const customers = []
    return (
        <CustomersDiv>
            <CustomersHead />
            <RegUsers>Regular Users</RegUsers>
            <AllCustomers>
                {
                    customers.length===0?
                        <EmptyDiv>
                            <img src={empty} alt='empt' />
                            <h3>Opps! No Customers Yet</h3>
                            <p>Build members to get all customer's data</p>
                            {/* <button>Add New Member</button> */}
                        </EmptyDiv> :
                        <>
                            <Customer />
                            <Customer />
                            <Customer />
                            <Customer />
                            <Customer />
                            <Customer />
                            <Customer />
                            <Customer />
                        </>
                }
            </AllCustomers>
            <Pagination />
        </CustomersDiv>
    )
}

import React from 'react'
import Face from 'assets/svg/face.svg'
import { BestDiv } from './style'

export default function BestPerformance({name='Adewale Adedamola',handle='@adeamojdnj899'}) {
    return (
        <BestDiv>
          <img src={Face} alt='looks' />
          <div>
              <p>{name}</p>
              <small>{handle}</small>
          </div>
        </BestDiv>
    )
}

import { useModalContext } from 'context/allModalContext'
import React, { useState, useEffect } from 'react'
import SelectUserModal from './index'
import { Holder, UsersContainer, UserSearch, FinishButton, Back, FinishDiv } from './style'
import User from './user'
import { membersData } from './userData'


export default function SelectUsers() {
    const { modals, setModals } = useModalContext()
    const [allMembers, setAllMembers] = useState([])
    const [marked,setMarked] = useState(0)

    function closeModal(){
        setModals({
            ...modals,
            selectUserModal:false
        })
    }

    // function getMarked(){
    //     const marked = allMembers.filter((member)=> member.isChecked === true)
    //     return marked.length
    // }

    // const updatedMarked = allMembers.filter((member)=> member.isChecked === true)
    // setMarked(updatedMarked)
    useEffect(()=>{
        setMarked('')
        setAllMembers(()=>{
            const newData =  membersData.map((member,i)=>(
                {...member,
                 isChecked:false,
                 index: i
              }))

              return [...newData]
          })
    },[])

    return (
        <SelectUserModal show={false} modalClosed={closeModal}>
            <UserSearch>
                {/* {console.log('allMembs',allMembers)} */}
               <p>{marked}/36 selected</p> 
                <input placeholder= 'search a user' />
            </UserSearch>
            <UsersContainer>
                {
                    // allMembers[0]?.isChecked?
                    membersData.map((data, index)=>{
                        return (
                        <Holder >
                            <User index={index} data={data} setAllMembers={setAllMembers} allMembers={allMembers} />
                        </Holder>
                    )}) 
                    // : null
                }
                {/* <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>
                <Holder >
                    <User />
                </Holder>                */}
            </UsersContainer>
            <FinishDiv>
                <Back>Go Back</Back>
                <FinishButton>Finish</FinishButton>
            </FinishDiv>
        </SelectUserModal>
    )
}

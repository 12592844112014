import LadyPic from 'assets/svg/lady.svg'

export const topSellersData = [
    {
        name: 'Sarah Thomas',
        location: 'Lagos, Nigeria',
        bg: 'yellow',
        image: LadyPic,
        total: '5768'
    },
    {
        name: 'John Tommy',
        location: 'Rivers, Nigeria',
        bg: 'blue',
        image: LadyPic,
        total: '3461'
    },
]
import React from 'react'

export default function Delegate({headerText, text}) {
    return (
        <>
           <h2>{headerText}</h2>
            <p>{text}</p>  
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { DescriptionDiv, ServiceProductInput, ServiceProviderDiv, TitleText } from './style'
// import { Multiselect } from 'multiselect-react-dropdown';
// import OtherTag from 'components/onboading/describeForm/otherTag'
import { GETCATEGORIES } from 'routes/backend'
import DropDown from 'components/onboading/describeForm/dropDown'
import { useCorporateProduct } from '../../../context/addProduct'
import SpottrPlaces from 'components/dashboard/main/GoogleMap/reactPlaces';
// import {DescribeArea} from '../welcomePage/style'

export default function ServiceProduct({handleChange}) {

    const {corporateProduct,setCorporateProduct} = useCorporateProduct()
    const {data} = corporateProduct
    const [categories, setCategories] = useState([])
    const [textCount, setTextCount] = useState(0)

   function handleCategory (selected){
       setCorporateProduct({
           ...corporateProduct,
           data:{
               ...corporateProduct.data,
               categoryId: [...selected]
           }
       })
   }

//    function handleChange (e){
//        setCorporateProduct({
//            ...corporateProduct,
//            data:{
//                ...corporateProduct.data,
//                [e.target.name]: e.target.value
//            }
//        })
//    }

    function onTextChange(e){
        let letters = e.target.value
        if(letters.length === 500){
            alert('Not More Than 500 Characters')
            return;
        }
        setTextCount(letters.length)
        setCorporateProduct({
            ...corporateProduct,
            data:{
                ...corporateProduct.data,
                description: letters
            }
        })
    }

    useEffect(()=>{
        fetch(GETCATEGORIES)
            .then(res=>res.json())
            .then(data=>{
                setCategories([...data.data])
            })
            .catch(err=>console.log(err))
    },[])

    return (
        <ServiceProviderDiv>
            <TitleText>Service/Product Name</TitleText>
            <ServiceProductInput value={data?.productName || ''} name='productName' onChange={handleChange} placeholder='Type a name' />
            <small style={{fontSize:'9px', maxWidth:'350px', margin:'20px 20px'}}>
                Use something quite Straight forward to help 
                your users find you quickier. E.g: PlayStation 5.
            </small>

            <div>
                <TitleText style={{marginTop:'10px'}}>Service/Product Category</TitleText>         
                <DropDown noText name='categoryId' handleChange={handleCategory} data={categories} />
            </div>

            <TitleText style={{marginTop:'10px'}}>Price (₦)</TitleText>
            <ServiceProductInput name='amount' value={data?.amount || ''}  onChange={handleChange} type='number' placeholder='Product Price' />


            <TitleText style={{marginTop:'10px'}}>Location</TitleText>
            {/* <ServiceProductInput type='number' placeholder='Product Location' /> */}
            <SpottrPlaces corporateUser={corporateProduct} setCorporateUser={setCorporateProduct} />
            <DescriptionDiv style={{marginTop:'20px'}}>
                <div>
                <TitleText style={{fontSize:'16px', margin:'0'}}>Description</TitleText>
                <p>{textCount}/500</p>
                </div>
                <textarea value={data?.description || ''} onChange={onTextChange} />
                <small style={{fontSize:'9px', maxWidth:'350px', margin:'20px 20px'}}>
                Make sure you are descriptive and detailed as it assure trust with users
            </small>            
            </DescriptionDiv>
            
            {/* <small>Make sure you are descriptive and detailed as it assure trust with users</small> */}
            {/* <DropDown data={categories} styles={{fontSize:'20px'}} /> */}
            {/* <OtherTag corporateUser = {corporateProduct} setCorporateUser = {setCorporateProduct} /> */}
        </ServiceProviderDiv>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import Modal from 'components/UI/Modal'
import UploadImages from './uploadImages'
import { AddProductDiv, ProductButton, ProductMainArea } from './style'
import { useCorporateProduct } from 'context/addProduct'
import {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
// import { UPLOADMULTIPLE } from 'routes/backend'
import ShowUploads from './showUploads'
import ServiceProduct from './serviceProduct'
import { ADDPRODUCT, GETCATEGORIES, UPLOADMULTIPLE } from 'routes/backend'
import { useAuthContext } from 'context/AuthContext'
import Toast from 'components/UI/Toast'
import asyncFetch from 'utilities/asyncFetch'
import {useHistory} from 'react-router-dom'


export default function AddProduct() {
    const history = useHistory()
    const {corporateProduct, setCorporateProduct} = useCorporateProduct()
    const {address} = corporateProduct
    const {user} = useAuthContext()
    const {page, showProductModal} = corporateProduct
     const [lat, setLat] = useState('')
     const [lng, setLng] = useState('')
    const [showToast, setShowToast] = useState(false)
    const [error, setError] = useState(false)
    const btnRef = useRef('')
    const [isLoading, setIsLoading] = useState(false)
    const [url, setUrl] = useState([])
    const [myFiles, setMyFiles] = useState([])
    const [ setCategories] = useState([])

    function handleChange(e){
        setCorporateProduct({
            ...corporateProduct,
            data:{
                ...corporateProduct.data,
                [e.target.name]:e.target.value
            }
        })
    }

    const handleSelect = address => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => console.log('Success', latLng))
          .catch(error => console.error('Error', error));
      };

      handleSelect('Lagos Nigeria')

      useEffect(()=>{
        navigator.geolocation.getCurrentPosition(function(position) {
            // setPosition([...position, position.coords])
            setLat(position.coords.latitude)
            setLng(position.coords.longitude)
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            return {

            }
            //console.log('position:',position)
          });
      }, [])

    function onRestart(){
        setUrl([])
        setMyFiles([])
        setCorporateProduct({
            ...corporateProduct,
            page: page - 1
        })
    }

    function createProduct(){
        setIsLoading(true)
        btnRef.current.textContent = 'Uploading Images...'
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${user.token}`);
        let formdata = new FormData();
        for (var i = 0; i < myFiles.length; i++) {     
          formdata.append("file", myFiles[i], url[i]);
      }
      
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(UPLOADMULTIPLE, requestOptions)
        .then(response => response.json())
        .then(imageLinks => {
            console.log("imageLinks",imageLinks)
            btnRef.current.textContent = 'Creating Product...'
            if(imageLinks.message.includes('uploaded')){
                setCorporateProduct({
                    ...corporateProduct,
                    data: {
                        ...corporateProduct.data,
                        images: [...imageLinks.data]
                    }
                })
                setIsLoading(true)

            const productData = {
                ...corporateProduct.data,
                images: [...imageLinks.data],
                address,
                currency: 'NGN',
                amount: Number(corporateProduct.data.amount),
                lat: lat,
                lng: lng           
            }

            console.log(productData)
            asyncFetch('POST',user.token,productData,ADDPRODUCT)
                .then(res=> {
                    setIsLoading(false)
                    // btnRef.current.textContent = 'Submit'
                    console.log("responseData",res)
                    if(res.message.includes('Product created')){
                        setCorporateProduct({
                            ...corporateProduct,
                            showProductModal: false
                        })
                        history.push(`/dashboard/product/${res.data.id}`)
                    }
                })
                .catch(err=> {
                    btnRef.current.textContent = 'Create Product'
                    console.log("error",err)
                })
            }else{
                setError('Upload Failed')
                setShowToast(true)
            }           
        })
        .catch(error => {
            console.log('error', error)
            setIsLoading(false)
            setError('Upload Failed')
            setShowToast(true)
            btnRef.current.textContent = 'Continue'
        })    
      }

    function closeProductModal(){
        setCorporateProduct({
            ...corporateProduct,
            showProductModal: false
        })
    }

    function onContinue(){

        if(page===0){
            if(myFiles.length === 0) {
                setError('Upload product images')
                setShowToast(true)
                return
            }
            myFiles.forEach(img=>{
                console.log('imace',URL.createObjectURL(img))
                let newUrl = URL.createObjectURL(img)
                // setUrl([...url])
                url.push(newUrl)
            })
            // sendProduct()
        }

        if(page===1){
            setIsLoading(false)
            btnRef.current.textContent = 'Create Product'
            setCorporateProduct({
                ...corporateProduct,
                page: page + 1,
            })
            return;
        }

        if(page===2){
            createProduct()
            return;
        }

        setCorporateProduct({
            ...corporateProduct,
            page: page + 1
        })
    }

    function onBack(){
        if(page>0){
            setCorporateProduct({
                ...corporateProduct,
                page: page - 1
            })
        }
    }

     function handleFile(e){  
        const myFile =  e.target.files;
        setMyFiles([...myFile])
        // for (let i = 0; i < myFiles.length; i++) {
        //     console.log(myFiles[i])
        //     setUrl([...url,URL.createObjectURL(myFiles[i])])
        //     setMyFiles([...myFiles,myFile[i]])
        // }
    }

    useEffect(()=>{
        if(myFiles.length > 3){
            setError('3 images allowed')
            setShowToast(true)
            setCorporateProduct((prev)=>{
                return{
                    ...prev,
                    page: 0
                }
            })
        }
        if(myFiles.length===0){
            setCorporateProduct((prev)=>{
            return{
                ...prev,
                page: 0
            }
        })
        }

        fetch(GETCATEGORIES)
            .then(res=>res.json())
            .then(data=>{
                setCategories([...data.data])
            })
            .catch(err=>console.log(err))
    },[myFiles.length, setCategories, setCorporateProduct])

    return (
            <Modal width={page===2?'500px':null} padding={page===2?'20px 50px':'20px'} show={showProductModal} modalClosed={closeProductModal} >
                <Toast icon='error' description={error} showModal={showToast} setShowModal={setShowToast} />
                <AddProductDiv>
                    {console.log("corporateProduct",corporateProduct)}
                    {console.log('history',history)}
                    <ProductMainArea>
                        {page===0?<UploadImages myUploads={myFiles} handleFile ={handleFile} /> : null}
                        {page===1?<ShowUploads restart={onRestart} myUploads={myFiles} images={url} handleFile ={handleFile} /> : null}
                        {page===2?<ServiceProduct handleChange ={handleChange} /> : null}
                    </ProductMainArea>
                    <ProductButton  ref={btnRef} disabled={isLoading}  onClick={onContinue} >Continue</ProductButton>
                    <p onClick={onBack}> back </p>
                </AddProductDiv>
            </Modal>
    )
}

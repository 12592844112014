import React from 'react'
import { Body, EmptyNotificationDiv, Head } from './style'
import empty from 'assets/svg/emptyMessage.svg'

export default function EmptyNotification({id='message', topic='Chat with Vendor'}) {
    return (
        <EmptyNotificationDiv>
            <Head>
                <h1>Feedback</h1>
                <p>You have no new {id}</p>
            </Head>
            <Body>
                <img src={empty} alt={id} />
                <h3>{topic}</h3>
                <p style={{display:id!=='message'?'none':null}}>Tap <b>New messages</b> to chat with a 
                    vendor you want to meet</p>
                <button style={{display:id!=='message'?'none':null}}>New Message</button>
            </Body>
        </EmptyNotificationDiv>
    )
}

import styled from 'styled-components/macro'

export const BestRatingContainer = styled.div`
    background: #FFFADD;
    border: 0.757086px solid rgba(154, 159, 191, 0.25);
    box-sizing: border-box;
    border-radius: 2.36589px;
    width: 32%;
    min-width : 98.58px;
    height : 150.43px; 
    padding: 10px 10px 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 10px;
`

export const RatingImage = styled.img`
    width: 45px;
`

export const RatingPinDiv = styled.div`
     position: relative;
    bottom: 5px;
    left: 3px;

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 5.52042px;
        line-height: 150%;
        color: #929AA7;
    }
`

export const StarDiv = styled.img`
    position: relative;
    bottom: 12px;
    right: 30px;
`

export const RatingPin = styled.img`   
    position: relative;
    /* bottom: 2px; */
`

export const RatingText = styled.p`
    position: relative;
    bottom: 24px;
    left: 25px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 6.47805px;
    line-height: 9px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
`

export const RatingNumbers = styled.div`
    background: rgba(255, 178, 17, 0.15);
    border-radius: 2.36589px;
    Width : 65.46px;
    Height : 18.14px;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 6.30905px;
        line-height: 9px;
        text-align: center;
        color: #E09B09;
    }
`
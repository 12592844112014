import styled from 'styled-components/macro'

export const MetricsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: rgba(59, 59, 59, 0.7);
        text-align: center;

    }

    h2{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        color: #274B89;
        text-align: center;
    }

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        color: rgba(59, 59, 59, 0.7);
        text-align: center;
    }
`
export const data = [
    {
      "name": "Jan",
      "uv": 1000,
      "pv": 0,
      "amt": 0
    },
    {
      "name": "Feb",
      "uv": 0,
      "pv": 0,
      "amt": 2210
    },
    {
      "name": "Mar",
      "uv": 0,
      "pv": 0,
      "amt": 2290
    },
    {
      "name": "Apr",
      "uv": 0,
      "pv": 0,
      "amt": 2000
    },
    {
      "name": "May",
      "uv": 0,
      "pv": 0,
      "amt": 2181
    },
    {
      "name": "Jun",
      "uv": 0,
      "pv": 0,
      "amt": 2500
    },
    {
      "name": "Jul",
      "uv": 0,
      "pv": 0,
      "amt": 2500
    },
    {
      "name": "Aug",
      "uv": 0,
      "pv": 0,
      "amt": 2500
    },
    {
      "name": "Sep",
      "uv": 0,
      "pv": 0,
      "amt": 2500
    },
    {
      "name": "Oct",
      "uv": 0,
      "pv": 0,
      "amt": 2500
    },
    {
      "name": "Nov",
      "uv": 0,
      "pv": 0,
      "amt": 2500
    },
    {
      "name": "Dec",
      "uv": 90,
      "pv": 0,
      "amt": 2100
    }
  ]
import React from 'react'
import { FundButtonDiv } from './style'

export default function FundButton() {
    return (
        <FundButtonDiv>
            <div>+</div>
            <p>Fund Wallet</p>
        </FundButtonDiv>
    )
}

import BrandColors from 'GlobalStyles/utils/colors'
import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const LoginPageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
`

export const FormContainer = styled.div`
    width: 50vw;
    margin-left: 50vw;
    padding: 50px;
    margin-top: 10px;
    /* margin: 60px 80px; */

    ${media.tablet`
        width: 100%;
        padding: 20px;
        margin: 0;
    `}
    ${media.mobile`
        width: 100%;
        height: 100%;
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    `}
`

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;

    ${media.mobile`
        /* margin-top: -300px */
    `}
`

export const LoginButton = styled.button`
    color: white;
    background-color: ${({disabled})=>disabled===true?'#192A53':BrandColors.brandBlue};
    padding: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`

export const UseSocials = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    align-items: center;
`
export const LoginText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    /* line-height: 33px; */
    color: #274B89;
    margin-bottom: 25px;
`

export const ForgotPasswordText = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #274B89;
`
import React from "react";
import AddCorporateProduct from "./addProduct.jsx";
import AllModals from "./allModalContext.jsx";
import Authorize from "./AuthContext.jsx";
import OnboardingUser from "./onboarding.jsx";
import Pagination from "./Pagination.jsx";
import Registration from "./RegContext.jsx";
import CorporateUser from "./userDetails.jsx";

function Store({ children }) {
  return (
    <Authorize>
      <Registration>
        <CorporateUser>
          <OnboardingUser>
            <AddCorporateProduct>
              <Pagination>
              <AllModals>
                {children}
              </AllModals>
              </Pagination>
            </AddCorporateProduct>
          </OnboardingUser>
        </CorporateUser>
      </Registration>
    </Authorize>
  );
}

export default Store;

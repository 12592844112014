import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const TransactionContainer = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;

    ${media.tablet`
        flex-direction: column;
    `}
`

export const TransHistory = styled.div`
    width: 60%;
    border-right: 2px solid #ececec;

    ${media.tablet`
        width: 100%;
        overflow: auto;
        border-right: none;
    `}
`

export const TransDetails = styled.div`
    width: 40%;

    ${media.tablet`
        width: 100%;
    `}
`

export const TransactionDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    img{
        width: 21px;
        height: 21px;
    }

    
`

export const TransTopic = styled.div`

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;

    }

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 150%;
        color: #929AA7;
    }

    ${media.tablet`
        justify-content: flex-start;
        margin-right: 20px;
    `}
`

export const TransDetailsDiv = styled.div`
    width: 100%;
    padding: 0 10px;
    
    h2{
        color: #274B89;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 29.6299px;
        line-height: 40px;
        display: flex;
        align-items: center;
    }
`
export const DetailsHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    div{
        display: flex;
        align-items: center;

        p{
            margin-left: 10px;
            color: #39B54A;
        }
    }

`

export const DetailsDivArea = styled.div`
    margin-top: 25px;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 9.87662px;
        line-height: 13px;
        color: #929AA7;
    }

    h3{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14.8149px;
        line-height: 20px;
        color: #274B89;
    }
`

export const DetailsSpread = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
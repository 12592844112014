import React from 'react'
import Activity from './activity'
import { ActivitiesDiv } from './style'

export default function Acitivities() {
    return (
        <ActivitiesDiv>
            <Activity />
            <Activity />
            <Activity />
            <Activity />
            <Activity />
            <Activity />
            <Activity />
        </ActivitiesDiv>
    )
}

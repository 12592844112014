import React from 'react'
import { SocialButtonContainer, SocialIcon } from './style'

export default function SocialButton({link, icon}) {
    return (
        <SocialButtonContainer>
            <SocialIcon src={icon} alt='icon' />
        </SocialButtonContainer>
    )
}

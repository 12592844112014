import OTPField from 'components/onboading/otpField'
import { Div, SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import BrandColors from 'GlobalStyles/utils/colors'
import React from 'react'
import { RegText } from './style'

export default function OTPForm() {
    return (
        <div>
            <OTPField />
            <Div
                display= 'flex'
                justify= 'center'
            >
                <RegText>I did not receive it.<SpottrLink to='' color={BrandColors.brandColor}> Please send a new one </SpottrLink></RegText>
            </Div>
        </div>
    )
}

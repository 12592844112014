import React from 'react'
import addCommas from 'comma-number'
import { topSellersData } from 'pages/dashboard/main/customers/customer/mockData'
import { Div, SpottrP, Img } from 'GlobalStyles/spotrrStyles/style'
import Colors from "GlobalStyles/utils/appColors";


export default function ProductInfo({brand='Coker & Sons Ltd.', image=topSellersData[0].image,name=topSellersData[0].name,category='Coker & Sons Ltd.',address=topSellersData[0].location,amount=topSellersData[0].total}) {
    return (

            <Div
    //   border="0.96px solid rgba(154, 159, 191, 0.25)"
      boxShadow="0px 4px 20px rgba(0, 0, 0, 0.07)"
      backgroundColor="#FFF"
      padding="5px"
      width="316px"
      height="120px"
      margin="0px 10px 10px 5px"
      br="5px"
    >
      <Div display="flex">
        <Img w="70px" height="70px" br="3px" src={image} alt={image} />
        <Div
        margin="0px 0px 0px 10px"
        width="100%"
          display="flex"
          flexDirection="column"
        >
          <Div>
            <SpottrP fs="15px" fw="bold" color={Colors.darkblue}>
              {name}
            </SpottrP>
            <SpottrP fs="14px" fw="bold" color={Colors.blue}>
              {category}
            </SpottrP>
            <SpottrP fs="12px" color={Colors.darkblue}>{brand}</SpottrP>
          </Div>
          <Div
            width="100%"
            display="flex"
            justify="space-between"
            alignI="center"
            margin="15px 0px 0px 0px"
          >
            <SpottrP fs="12px" fw="bold" color={Colors.ligterGrey}>{address}</SpottrP>
            <Div
              display="flex"
              justify="flex-end"
              alignI="center"
            >
              <Div
                br="3px"
                padding="5px 10px"
                width="100%"
                backgroundColor={Colors.lightGreen}
              >
                <SpottrP fs="12px" >N{addCommas(amount)}</SpottrP>
              </Div>
              <SpottrP fs="9px" ml="5px"> /each</SpottrP>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>

    )
}
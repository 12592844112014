import styled from 'styled-components/macro'

export const TopSellersContainer = styled.div`
    width: 19%;
    max-width: 230px;
    min-width: 200px;
    min-height: 92px;
    box-sizing: border-box;
    border-radius: 2.50272px;
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    background: #FFFFFF;
    border: 0.96px solid rgba(154, 159, 191, 0.25);
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 15px;
    padding: 0 10px;
`

export const TopsellerImage = styled.img`
    border-radius: 50%;
    border: 2px solid #C2E0FF;
    width: 70px;
    height: 70px;
`

export const TotalDiv = styled.div`
    background: rgba(227, 232, 240, 0.51);
    border-radius: 2.50272px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-top: 10px;

    img{
        width: 14px;
        margin-right: 5px;
    }
`

export const DetailsDiv = styled.div`
    padding: 10px 4px 10px 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
 
`
export const NameText = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #061E48;
`
export const LocationText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #929AA7;
`

export const TotalText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #274B89;
`
// import SelectInput from '@material-ui/core/Select/SelectInput'
// import { useCorporateUser } from 'context/userDetails'
import React from 'react'
import {DropdownDiv} from './style'
import { Multiselect } from 'multiselect-react-dropdown';

export default function DropDown({data,handleChange,styles,noText}) {
    // const {corporateUser, setCorporateUser} = useCorporateUser()
    return (
        <DropdownDiv>
            <p style={{display:noText?'none':'block'}}>Service/product Category</p>
           
            <Multiselect
                options={data}
                placeholder="Select Category"
                displayValue="name"
                onSelect={(selected)=> handleChange(selected)
                //     setCorporateUser({
                //     ...corporateUser,
                //     data:{
                //         ...corporateUser.data,
                //         businessCategory: [...selected]
                //     }
                // })
            }
                id="css_custom"
                style={{ chips: { background: "white",
                                  color:'grey', 
                                  border:'1px solid #C2E0FF' }, 
                        searchBox: { border: "none", 
                                    borderRadius: "0px",
                                    background: '#ECF7FF',
                                    padding: '8px'
                                },
                        optionContainer: { // To change css for option container 
                            border: '1px solid'
                            },
                        option: { // To change css for dropdown options
                            color: 'black',
                            // background: '#ECF7FF'
                        },
                    }}
                />
        </DropdownDiv>
    )
}

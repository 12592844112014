import HomeIcon from 'assets/svg/homeIcon.svg'
import MemberIcon from 'assets/svg/membersIcon.svg'
import CustomerIcon from 'assets/svg/customerIcon.svg'
import RequestIcon from 'assets/svg/requestIcon.svg'
// import SettingIcon from 'assets/svg/settingIcon.svg'
import greyHomeIcon  from 'assets/svg/greyHome.svg'
import whiteCustomer  from 'assets/svg/whiteCustomer.svg'
import whiteMember  from 'assets/svg/whiteMember.svg'
import whiteRequest  from 'assets/svg/whiteRequest.svg'
import settings  from 'assets/svg/settingIcon.svg'
import whiteSettings  from 'assets/svg/whiteSettings.svg'
import { DASHBOARDHOME,
         ALLMEMBERS,
         CUSTOMERS,
         REQUESTS,
         SETTINGS } from 'pages/dashboard/main/ROUTECONST'
    

export const menuData = [
    {
        icon: greyHomeIcon,
        white: HomeIcon,
        text: 'Home',
        isActive: true,
        link: DASHBOARDHOME
    },
    {
        icon: MemberIcon,
        white: whiteMember,
        text: 'Member',
        isActive: false,
        link: ALLMEMBERS
    },
    {
        icon: CustomerIcon,
        white: whiteCustomer,
        text: 'Customers',
        isActive: false,
        link: CUSTOMERS
    },
    {
        icon: RequestIcon,
        white: whiteRequest,
        text: 'Requests',
        isActive: false,
        link: REQUESTS
    },
    {
        icon: settings,
        white: whiteSettings,
        text: 'Settings',
        isActive: false,
        link: SETTINGS
    },
    // {
    //     icon: settings,
    //     white: whiteSettings,
    //     text: 'Log Out',
    //     isActive: false,
    //     link: '/'
    // },
]
import React from 'react'
import { CTtext} from '../chooseWallet/style'
import card from 'assets/svg/cards.svg'
import { PaymentChoiceDiv } from './style'

export default function PaymentChoice({icon= card, name='Pay with card', fee='N150 fee', setPage}) {
    return (
        <PaymentChoiceDiv>
            <div>
                <img src={icon} alt='card' />
                <h2 style={{fontSize:'15px'}}>{name}</h2>
            </div>
            <CTtext>{fee}</CTtext>
        </PaymentChoiceDiv>
    )
}

import React from 'react'
import { ActionDiv, CheckDiv, SingleRequestDiv } from './style'
import Face from 'assets/svg/face.svg'
import approve from 'assets/approve.png' 
import decline from 'assets/decline.png' 

export default function SingleRequest({name='Adewale Adedamola',handle='@adeamojdnj899'}) {
    return (
        <SingleRequestDiv>
            <div style={{display:'flex'}}>
                <img src={Face} alt='looks' />
                <div>
                    <p>{name}</p>
                    <small>{handle}</small>
                </div>
            </div>
            <ActionDiv>
                <CheckDiv>
                    <img src={approve} alt='app' />
                    <p>approve</p>
                </CheckDiv>
                <CheckDiv>
                    <img src={decline} alt='app' />
                    <small decline={true} >decline</small>
                </CheckDiv>
            </ActionDiv>
        </SingleRequestDiv>
    )
}

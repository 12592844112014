import React from 'react'

export default function Checking({headerText, text}) {
    return (
        <>
           <h2>{headerText}</h2>
            <p>{text}</p> 
        </>
    )
}

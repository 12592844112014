import Pagination from 'components/dashboard/main/pagination'
import React from 'react'
import { headData } from './data'
// import Pagination from './pagination'
import { EmptyDiv, TableDiv } from './style'
import Row from './tableRow'
import empty from 'assets/svg/empty.svg'
// import Fuse from 'fuse.js'

export default function Table({result}) {

    // function searchData(){
    //     const options = {
    //         // isCaseSensitive: false,
    //         // includeScore: false,
    //         // shouldSort: true,
    //         // includeMatches: false,
    //         // findAllMatches: false,
    //         // minMatchCharLength: 1,
    //         // location: 0,
    //         // threshold: 0.6,
    //         // distance: 100,
    //         // useExtendedSearch: false,
    //         // ignoreLocation: false,
    //         // ignoreFieldNorm: false,
    //         keys: [
    //           "title",
    //           "author.firstName"
    //         ]
    //       };
          
    //       const fuse = new Fuse(membersData, options);
          
    //       // Change the pattern
    //       const pattern = ''
          
    //       return fuse.search(pattern)
    // }
    const resultData = []

    return (
        <>
        <TableDiv>
           <table>
                <thead>
                    {
                      headData.map((head,i)=>(
                          <td key={i}>{head}</td>
                      ))  
                    }    
                </thead> 
                {
                    resultData.length > 0 ?
                        <tbody>
                        {
                            result.map((data,i)=>(
                                <Row index={i} id={i} data={data} />
                            ))
                        }    
                        </tbody>  : null
                }
            </table>
            </ TableDiv>
            {
                resultData.length===0?
                    <EmptyDiv>
                        <img src={empty} alt='empt' />
                        <h3>Opps! No Member Added Yet</h3>
                        <p>Tap Add New User to add new user to your team</p>
                        <button>Add New Member</button>
                    </EmptyDiv>: null
            }
            <Pagination />
        </>
    )
}

import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const ProductContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const AllProductsDiv = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    margin-left: 30px;
    margin-top: 30px;
    flex-wrap: wrap;
`

export const ProductWrap = styled.div`
    min-width: 30%;
    max-width: 360px;
    margin: 10px 10px 0 0;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    cursor: pointer;

    ${media.mobile`
        width: 100%;
    `}
`

export const Text = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #274B89;
`
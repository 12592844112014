import React from 'react'
import {DetailsDivArea} from './style'

export default function Detail({title,value}) {
    return (
        <DetailsDivArea>
            <p>{title}</p>
            <h3>{value}</h3>
        </DetailsDivArea>
    )
}

import React from 'react';
import GoogleMapReact from 'google-map-react';
import { useEffect } from 'react';
import spotIcon  from 'assets/svg/spotIcon.svg'

const containerStyle = {
  width: '400px',
  height: '400px'
};

const location = {
  lat: 6.4439338,
  lng: 3.5082615
};

// const location = {
//   lat: -3.745,
//   lng: -38.523
// };

// const options = {
//   zoomControlOptions: {
//     position: {
//       lat: -3.745,
//       lng: -38.523
//     } ,
//     // ...otherOptions
//   }
// }

function AnyReactComponent(){
  return(
    <img width='30px' src={spotIcon} alt='data' />
  )
}

export default function GoogleMaps({center= location, style=containerStyle, children}) {

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    console.log('maps',maps)
    console.log('map',map)
  };

  useEffect(()=>{

  },[center])

  return (
  // <div style={{borderRadius:'20px'}} >
    <GoogleMapReact 
      bootstrapURLKeys={{ key: 'AIzaSyDqoTtDYnAr80Z6wvOY9Qp4FKtMfeoaNdM'}}
      defaultCenter={center}
      defaultZoom={15}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      <AnyReactComponent
        lat={center.lat}
        lng={center.lng}
        text="My Marker"
      />
      {children}
    </GoogleMapReact>
  // </div>
  )
}
import Goback from "components/dashboard/main/goBack";
import ToggleSwitch from "components/dashboard/main/switch";
import Divider from "GlobalStyles/dividerLine";
import { Div, SpottrP } from "GlobalStyles/spotrrStyles/style";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SettingsItem from "./settingsItem";
import { SettingsContainer } from "./style";
import Transactionpin from "./transactionpin";

function Settings() {
  const history = useHistory();
  const [onSwitch, setOnSwitch] = useState(false);
  console.log("History", history);
  return (
    <div>
      <Goback handleGoback={history.goBack} />
      <br />
      <Div display="flex">
      <SettingsContainer>
        <Div padding="20px 15px">
          <Div padding="10px 0px" mt="10px">
            <SettingsItem
              label="Push Notifications"
              switchToggle={
                <ToggleSwitch
                checkedVal={onSwitch}
                  handleSwitch={(e) => setOnSwitch(e.target.checked)}
                />
              }
            />
          </Div>
          <Div padding="10px 0px" mt="10px">
            <SettingsItem
              label="Email Notifications"
              switchToggle={
                <ToggleSwitch
                  handleSwitch={(e) => setOnSwitch(e.target.checked)}
                />
              }
            />
          </Div>
          <Div padding="10px 0px" mt="10px">
            <SettingsItem
              label="Sounds"
              switchToggle={
                <ToggleSwitch
                  handleSwitch={(e) => setOnSwitch(e.target.checked)}
                />
              }
            />
          </Div>
          <Div padding="10px 0px" mt="10px" cursor="pointer" >
            <SettingsItem label="Upgrade Account" arrow={true} />
          </Div>
          <Divider />
          <Div padding="10px 0px" cursor="pointer" mt="10px">
            <SettingsItem label="Manage Opportunities" arrow={true} />
          </Div>
          <Divider />
          <Div mt="20px">
            <SpottrP ml="19px" mb="40px">
            Wallet
            </SpottrP>
            <Div padding="10px 0px" cursor="pointer">
              <SettingsItem label="Wallet Settings" arrow={true} />
            </Div>
          </Div>
          <Divider />
          <Div mt="20px" backgroundColor="">
            <SpottrP ml="19px" mb="40px">
              Security
            </SpottrP>
            <Div padding="10px 0px" mt="10px" cursor="pointer">
              <SettingsItem label="Password" arrow={true} />
            </Div>
            <Div
              padding="10px 0px"
              backgroundColor=""
              mt="10px"
              cursor="pointer"
            >
              <SettingsItem label="Set Transaction PIN" arrow={true} />
            </Div>
          </Div>
          <Divider />
          <Div mt="20px" backgroundColor="">
            <SpottrP ml="19px" mb="40px">
              Data
            </SpottrP>
            <Div padding="10px 0px" mt="10px" cursor="pointer">
              <SettingsItem label="Dashboard Analysis" arrow={true} />
            </Div>
            <Div padding="10px 0px" mt="10px" cursor="pointer">
              <SettingsItem label="Deactivate account" arrow={true} />
            </Div>
          </Div>

          <Divider />
        </Div>
      </SettingsContainer>
      <Transactionpin>
        
      </Transactionpin>
      </Div>
    </div>
  );
}

export default Settings;

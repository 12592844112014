import styled from 'styled-components/macro'

export const AvailabilityDiv = styled.div`
    text-align: center;

    h2{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #061E48;
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #4C596F;
        margin-top: 20px;
        margin-bottom: 20px;
    }
`

export const ButtonArea = styled.div`
    height: 41px;
    width: 100%;
    align-self: flex-end;
`

export const NextButton = styled.button`
    width: 50%;
    height: 100%;
    background: ${({blue})=>blue?'#274B89':'white'};
    color: ${({blue})=>blue?'white':'black'};
    border: none;
    cursor: pointer;
`
import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const PaginationDiv = styled.div`
    position: fixed;
    bottom: 36px;
    height: 45px;
    border-radius: 10px;
    /* width: 88%; */
    width: calc(100vw - 11vw);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: 100;

    ${media.mobile`
        bottom: 0px;
        
    `}

    @media (max-width: 768px) {

  }
`

export const ViewAll = styled.p`
    color: #274B89;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
`

export const PaginatingArea = styled.div`
    color: #274B89;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    display: flex;

    p{
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;

        
    }
`

export const Pages = styled.div`
    display: flex;

    p{
        &.active{
            border: 2px solid;
        }
    }
`
import React from 'react'
import { EmptyDiv } from '../customers/style'
import RequestsHead from './head'
import SingleRequest from './singleRequests'
import { AllRequestDiv, RequestsDiv } from './style'
import empty from 'assets/svg/customerEmpty.svg'

export default function Requests() {
    const requests = []
    return (
        <RequestsDiv>
            <RequestsHead />
            <AllRequestDiv>
            {
                requests.length===0?
                    <EmptyDiv>
                        <img src={empty} alt='empt' />
                        <h3>No New Request</h3>
                        <p>Tell all users to join corporate body</p>
                        {/* <button>Add New Member</button> */}
                    </EmptyDiv> :
                    <>
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                        <SingleRequest />
                    </>
                }
                
            </AllRequestDiv>
        </RequestsDiv>
    )
}

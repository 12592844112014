import React from 'react'
import depositIcon from  'assets/deposit.png'
import { TransactionDiv, TransTopic } from './style'

export default function Transaction() {
    return (
        <TransactionDiv>
            <img src={depositIcon} alt='dep' />
            <TransTopic>
                <p>You Deposited</p>
                <small>cash</small>
            </TransTopic>
            <TransTopic>
                <p>@adedamola456</p>
                <small>username:</small>
            </TransTopic>
            <TransTopic>
                <p>tdg87wdgh7udh32bd...</p>
                <small>transaction ID:</small>
            </TransTopic>
            <TransTopic>
                <p style={{color:'#39B54A'}}>N500</p>
                <small>12/03/2020</small>
            </TransTopic>
        </TransactionDiv>
    )
}

import styled from 'styled-components/macro'

export const SingleButtonDiv = styled.div`
    background: #ECF7FF;
    border: 1px solid #F6F6F6;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100px;
    height: 41px;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #274B89;
    }

    img{
        width: 18px;
        height: 18px;
    }
`
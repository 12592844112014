import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const TableDiv = styled.div`
    width: 100%;
  

    table{
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
        min-height: 100%;

        thead{
            font-weight: bold;
        }

        ${media.tablet`
            min-width: 1000px;
        `}

        tbody{
            background-color: #F6F6F6;
  
        }
    }
`

export const EmptyDiv = styled.div`
    width: 85vw;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #061E48;
        margin-top: 10px;
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #061E48;
    }

    button{
        width: 200px;
        padding: 13px;
        background: #274B89;
        box-shadow: 0px 10.1752px 22.6117px rgba(171, 180, 189, 0.148862);
        border-radius: 4.52233px;
        color: white;
        border: none;
        margin-top: 10px;
        cursor: pointer;
    }
`
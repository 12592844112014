import styled from 'styled-components/macro'

export const DataDetailsContainer = styled.div`
    background: #FFFFFF;
    border: 0.9px solid #E1EFFB;
    box-sizing: border-box;
    border-radius: 10px;
    min-width : 235.71px;
    height :107px;
    padding: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #5E7398;
    }

`
export const FirstLane = styled.div`
    display: flex;
    justify-content: space-between;
`
export const SecondLane = styled.div`
    display: flex;
    justify-content: space-between;
`

export const MainText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #274B89;
`

export const IconHolder = styled.div`
    height:33px;
    width: 33px;
    background: #FFFFFF;
    box-shadow: 0px 9px 13px rgba(39, 75, 137, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: center !important;
    align-items: center;
`

export const UpdateDiv = styled.div`
    display: flex;
    /* width: 30px; */
    img{
        width: 10px;
    }
`
export const UpdatedText = styled.div`
    font-family: Nunito;
    margin-right: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${({isIncreasing})=>isIncreasing===false?'#FF4B3E': '#39B54A'}
`

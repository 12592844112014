import React from 'react'
import { TransactionContainer, TransDetails, TransHistory } from './style'
import Transaction from './transaction'
import TransactionDetails from './TransactionDetails'

export default function TransactionActivities() {
    return (
        <TransactionContainer>
            <TransHistory>
                <Transaction />
                <Transaction />
                <Transaction />
                <Transaction />
                <Transaction />
                <Transaction />
                <Transaction />
            </TransHistory>
            <TransDetails>
                <TransactionDetails />
            </TransDetails>
        </TransactionContainer>
    )
}

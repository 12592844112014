import React, { useState } from 'react'
import { EyeIcon, Input, InputFieldContainer, LabelText } from './style'
import Eye from 'assets/svg/eye.svg'

export default function InputField({placeholder, onBlur, value, handleFormChange, type, name, displayEye, label='Email Address', show}) {
    const [inputType, setInputType] = useState(type)

    function onClickEye(){
        if(inputType === 'password'){
            setInputType('text')
        }else{
            setInputType('password')
        }
    }
    return (
        <>
        <LabelText show={show} >{label}</LabelText>
        <InputFieldContainer>
        <Input
            placeholder={placeholder}
            type={inputType}
            name= {name}
            onChange={handleFormChange}
            value = {value}
            onBlur={onBlur}
        />
        <EyeIcon onClick={onClickEye} display={displayEye} src={Eye} alt='icon' />
        </InputFieldContainer>
        </>
    )
}

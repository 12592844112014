import React from 'react'
import { SearchDiv, SearchInput } from './style'
import SearchIcon from 'assets/svg/search-icon.svg'

export default function Search() {
    return (
        <SearchDiv>
            <SearchInput placeholder='Type a keyword' />
            <img src={SearchIcon} alt='search' />
        </SearchDiv>
    )
}

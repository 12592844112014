import React from 'react'
import { SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import { MenuIcon, MenuItem, MenuText } from './style'

export default function BigMenu({icon, text, isActive, onActiveClick, link}) {
    return (
        <SpottrLink to={link}>
                <MenuItem onClick={()=>onActiveClick(text)} className={isActive?'activeLink':null} >
                    <MenuIcon src={icon}/>
                    <MenuText>{text}</MenuText>
                </MenuItem>
        </SpottrLink>
    )
}

import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const WelcomePageContainer = styled.div`
    width: 100vw;
    max-width:-webkit-fill-available;
    /* height: 100vh; */
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    overflow-y: hidden;
    overflow-x: hidden;

    ${media.tablet`
        /* margin: 0 auto; */
    `}
`

export const BackArea = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`


export const FormArea = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    /* Div{
        width: 90%;
        margin-top: 20px;
    } */


    ${media.tablet`
        width: 100%;
        margin-bottom: 20px;
    `}
`
    
export const CompanyDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 80%;
    width: 80%;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;


    Div{

        p{
            font-family: Nunito;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            color: #274B89;
        }
    }

    img{
        /* margin: 0 auto; */
    }

    ${media.tablet`
        width: 80%;
    `}

`
export const CompanyForm = styled.div`
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    width:100%;


p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
        position: relative;
        left: 20px;
        margin: 10px 0;
        float: left;
    }
`

export const AlertDiv = styled.div`

    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;

    img{
        margin: 0px 5px 0 0;
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 158.8%;
        color: #274B89;
    }

    ${media.tablet`
        width: 80%;
    `}
`

export const UploadImageDiv = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    input{
        position: absolute;
        bottom: 0;
        /* top:20px; */
        cursor:pointer;
        padding:40px;
        width:0px;
        opacity:0;
    }

p{
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #274B89;
}

img{
    width: 83px;
    height: 83px;
    border-radius: 50%;
    border: 2px solid #274B89;
}
`


export const BackDiv = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    width: 82px;
`

export const LocationFormContainer = styled.div`
    width: 80%;

`

export const ManualText = styled.p`

        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
        margin: 0 0 15px 10px;

`

export const LocationText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin: 20px 0;
    color: #274B89;

`

export const MapDiv = styled.div`
    background: #c4c4c4;
    border-radius: 10px;
    width: 100%;
    height: 250px;
    margin-bottom: 20px;

    iframe{
        width: 100%;
        height: 100%;
        border: none;
    }
`

export const LiveText = styled.div`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #274B89;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin:20px 0;

    img{
        margin: 0 5px 0 0;
    }
`

export const DescribeCompanyContainer = styled.div`
   width: 100%; 
   /* display: flex;
    flex-direction: column; */
    max-width: 80%;
    /* align-items: center;
    overflow-y: hidden;
    overflow-x: hidden; */
`

export const CountDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #274B89;

    }
`

export const DescribeForm = styled.form`

`

export const DescribeArea = styled.textarea`
    margin-top: 20px;
    background-color: #efeeee;
    height: 170px;
    max-height: 220px;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    border: none;
    padding: 5px;
    color: #676161;
`

export const WelcomeHomePageDiv = styled.div`
    width: 100vw;
    max-width:-webkit-fill-available;
    /* height: 100vh; */
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    overflow-y: hidden;
    overflow-x: hidden;

    ${media.tablet`
        /* margin: 0 auto; */
    `};
`
export const WelcomeRightLayout = styled.div`
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: right;
        color: #39B54A;
    }

    ${media.tablet`
        width: 100%;
        margin-bottom: 20px;
    `}
`

import { SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import React from 'react'
import { SingleMenuDiv } from './style'

export default function SingleMenu({
    text = "Home",
    link = "/",
    Active,
    handleCLickedActive,
  }) {
    return (
      <SpottrLink to={link}>
        <SingleMenuDiv>
          <p onClick={handleCLickedActive} className={Active}>
            {text}
          </p>
        </SingleMenuDiv>
      </SpottrLink>
    );
  }
  
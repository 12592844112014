import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const MenuContainer = styled.div`
    width: 88px;
    height: 100vh;
    position: absolute;
    top:0;
    left:0;
    background: #EDF3FA;
    box-shadow: 0px 2.5865px 12.9325px rgba(0, 0, 0, 0.07);
    border-radius: 1.93988px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    ${media.tablet`
        display: none;
    `}
`

export const MenuItem = styled.div`
    margin-top: 30px;
    width:55px;
    padding: 4px;
    color: #929AA7;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &.activeLink{
        background-color: #274B89;
        box-shadow: 0px 2.5865px 12.9325px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        color: white;
    }
`
export const MenuText = styled.small`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 19px;
`

export const MenuIcon = styled.img`

`

export const MobileMenuContainer = styled.div`
    display: none;
    position: absolute;
    top:0;

    img{
        cursor: pointer;
        width: 33px;
        z-index: 7;
    }

    ${media.tablet`
        /* margin-top: -15px; */
        display: flex;
        height: 50px;
        width: 100%;
        background-color: white;
        padding: 0 0 0 10px;
    `}
`
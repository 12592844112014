import LeftLayout from 'components/onboading/leftLayout'
import React from 'react'
import RegForm from './regForm'
import { JoinPageContainer } from './style'

export default function JoinPage() {
    return (
        <JoinPageContainer>
            <LeftLayout buttonText='Login' />
            <RegForm />
        </JoinPageContainer>
    )
}

import React from 'react'
import { 
    MemDiv, 
    MProfile, 
    MProfileDetails, 
    NameArea, 
    BarchartArea ,
    MemPicture,
    MetricsArea,
    StatsArea,
    StatDiv
} from '../style'
import { Div } from 'GlobalStyles/spotrrStyles/style'
import Sold from '../sold'
import Rated from '../rated'
import BreifInfo from '../briefInfo'
import Call from 'assets/mem/call.png'
import Message from 'assets/mem/message.png'
import Barcode from 'assets/mem/barcode.png'
import SingleButton from '../singleButtons'
import Metrics from '../metrics'
import MemPic from 'assets/mem/memPic.png'
import Google from 'assets/svg/google.svg'
import MemRating from 'assets/mem/memRating.png'
import Highlights from './highlights'
import Share from './shareDiv'
import BarGraph from 'components/dashboard/main/graph/barchart'

export default function SingleProfile() {
    return (
        <MProfile>
        <MemPicture src={MemPic} alt='icon' />
        <MProfileDetails>
            <Div display='flex' justify='space-around' >
                <NameArea>
                    <h2>Abdul, Mashir</h2>
                    <small>@abudulmashiirr</small>
                </NameArea>
                <img src={MemRating} alt='cion' />
                <div>
                    <Sold />
                    <Rated />
                </div>
            </Div>
            <BreifInfo />
            <Div display='flex'>
                <SingleButton icon={Call} title='Call' />
                <SingleButton icon={Message} title='Message' />
                <SingleButton icon={Barcode} title='My Barcode' />
            </Div>
            <MemDiv>
                <small>Email Address</small>
                <p>adedamolamoses@gmail.com</p>
            </MemDiv>
            <MemDiv>
                <small>Country</small>
                <p>Nigeria</p>
            </MemDiv>
            <MemDiv>
                <small>Login Type</small>
                <img src={Google} alt='googs'/>
            </MemDiv>
        </MProfileDetails>
        <MProfileDetails>
            <h6>Performance</h6>
            <h4>You reached 450 accounts in the month of March, compare to February</h4>
            <BarchartArea>
                {/* <BarChartImage src={Barchart} alt='bar'/> */}
                <BarGraph />
            </BarchartArea>
            <MetricsArea>
                <h6>Performance Metrics</h6>
                <h4>Stats compare to previous month</h4>
                <StatsArea>
                    <StatDiv>
                        <Metrics />
                        <Metrics text='Visits' number={38} percent='40%' />
                    </StatDiv>
                    <StatDiv>
                        <Metrics text='Contact' number={2} percent='87%'/>
                        <Metrics text='Request' number={10} percent='78%'/>
                    </StatDiv>
                </StatsArea>
            </MetricsArea>
        </MProfileDetails>
        <MProfileDetails>
            <Highlights />
            <Highlights title='Ads' />
            <Share />
            <Share title='Blacklist this user' color='red'/>
        </MProfileDetails>
    </MProfile>
    )
}

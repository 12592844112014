import React from 'react'
import { CoinAmountDiv } from './style'
import {ReactComponent as Down} from 'assets/svg/blackDown.svg'

export default function CoinAmount() {
    return (
        <CoinAmountDiv>
            <h2>Withdraw funds</h2>
            <h1>input Amount to withdraw</h1>
            <div>
                <input value={0} type='number' /> <p>NGN<Down /></p>
            </div>
            <button>Continue</button>
        </CoinAmountDiv>
    )
}

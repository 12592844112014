import styled from 'styled-components/macro'

export const SocialButtonContainer = styled.div`
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    margin-left: 15px;
`

export const SocialIcon = styled.img`
    /* width: 30px; */
`
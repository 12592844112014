import React from 'react'
import CoinAmount from './coinAmount'
import { BuycoinsContainer } from './style'

export default function BuyCoins() {
    return (
        <BuycoinsContainer>
            <CoinAmount />
        </BuycoinsContainer>
    )
}

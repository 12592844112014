import React from "react";
import { Div, Img, SpottrH, SpottrP } from "GlobalStyles/spotrrStyles/style";
import forwarrow from "assets/svg/bluearrow.svg";
import Colors from "GlobalStyles/utils/appColors";
function SettingsItem({ label, arrow, switchToggle }) {
  return (
    <Div display="flex" width="100%" justify="space-between" padding="0px 20px">
      <Div display="flex" alignI="center">
        <SpottrH color={Colors.primary} fw="bold" fs="18px">
          {label}
        </SpottrH>
        {label === "Upgrade Account" && (
          <Div ml="15px" backgroundColor={Colors.lightRed} br="3px">
            <SpottrP color={Colors.labelred}>basic</SpottrP>
          </Div>
        )}
      </Div>
      <Div>
        {arrow && <Img src={forwarrow} alt="forwarrow" />}
        {switchToggle && switchToggle}
      </Div>
    </Div>
  );
}

export default SettingsItem;

import styled from 'styled-components/macro'
import Popup from 'reactjs-popup';

export const EmptyNotificationDiv = styled.div`

    min-width: 281px !important;
    height: 364px;
    position: fixed;
    top: 120px;
    right: 100px;
    background-color: white;
    box-shadow: 0px 2.95622px 14.7811px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 20px;
    overflow: scroll;
`

export const Head = styled.div`
      h1{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 17.5686px;
        line-height: 24px;
        color: #274B89;

    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 8.78431px;
        line-height: 12px;
        color: #000000;
        margin-top: 5px;
    }
`

export const Body = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img{
        width: 100px;
    }

    h3{
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #274B89;
        margin-top: 10px;
    }

    p{
        font-weight: normal;
        font-size: 7.32026px;
        line-height: 10px;
        text-align: center;
        color: #274B89;
        margin-top: 10px;
    }

    button{
        color: white;
        padding: 13px 25px;
        border: none;
        position: absolute;
        cursor: pointer;
        bottom: 0;
        background: #274B89;
        box-shadow: 0px 5.06939px 11.2653px rgba(171, 180, 189, 0.148862);
        border-radius: 2.25306px;
    }
`

export const MessagePopUp = styled(Popup)`
  
`
import React from 'react'
import { CTtext, PriceDiv, WalletInfoDiv } from './style'


export default function WalletInfo({icon, name, currency, amount, CTvalue, setPage}) {
    return (
        <WalletInfoDiv onClick={()=>setPage(3)}>
            <PriceDiv>
                <h3><img src={icon} alt='ics' /> {name}</h3>
                <h2>{currency} {amount}</h2>
            </PriceDiv>
            <CTtext>{CTvalue}</CTtext>
        </WalletInfoDiv>
    )
}

import styled from 'styled-components/macro'
import Popup from 'reactjs-popup';
import media from 'GlobalStyles/utils/media';

export const WithdrawalContainer = styled.div`
    width: 377px;
    height: 395px;
    background-color: white;
    border-radius: 23px;
    padding: 20px;

    ${media.mobile`
        min-width: 90%;
        max-width: 90%;
        margin: 0 auto;
    `}
`

export const WithdrawalPopUp = styled(Popup)`

    &-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }


`


export const WithdrawalAmountDiv = styled.div`
    display: flex;
    flex-direction: column;

    button{
        width: 100%;
        padding: 18px;
        background: #274B89;
        box-shadow: 0px 9px 20px rgba(171, 180, 189, 0.148862);
        border-radius: 4px;
        border: none;
        color: white;
        position: relative;
        top:50px;
        cursor: pointer;
    }

    h2{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #274B89;
        margin-top: 20px;
        margin-left: 20px;
    }

    h1{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #274B89;
        margin-top: 20px;
        margin-left: 20px;
    }

    div{
        display: flex;
        align-items: center;
        margin-top: 70px;

        p{
            font-size: 13px;
        }
    }

    input{
        font-weight: 500;
        font-size: 48px;
        width: 50%;
        text-align: right;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        border: none;
        margin-right: 10px;
    }


`
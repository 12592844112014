import styled from 'styled-components/macro'

export const SharedDiv = styled.div`
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E7E7E7;
    margin-top: 10px;
`

export const SharedText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: ${({color})=>color==='red'?'#FF4B3E':'#274B89'};
`
import React, { useState } from 'react'
import Search from './searchIcon'
import { CompanyName, 
         CorporateHeader, 
         SpottrLogo, 
         NotificationIcon, 
         HeaderContainer, 
         ImageDiv, 
         NotificationArea, 
         NotificationDiv, 
         TitleContainer, 
         ProfilePicture, 
         HeaderMenuArea, 
         HeaderMenus, 
         AddNewCategories} from './style'
import Bell from 'assets/svg/bellIcon.jpg'
import Message from 'assets/svg/messageIcon.svg'
import DropDown from 'assets/svg/down.svg'
import Logo from 'assets/svg/logo.svg'
import {headerMenuData} from '../dashboardHeader/menu'
import SingleMenu from './singleMenu'
import { SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import { useCorporateProduct } from 'context/addProduct'
import { useAuthContext } from 'context/AuthContext'


// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import EmptyNotification from './messaging/EmptyNotification'
import { MessagePopUp } from './messaging/style'


export default function DashboardHeader({company='Dangote Limited PLC'}) {

    const {corporateProduct, setCorporateProduct} = useCorporateProduct()
    const {user} = useAuthContext()
    const [subHeaderMenu, setSubHeaderMenu] = useState(0);


    function productModal(){
        setCorporateProduct({
            ...corporateProduct,
            showProductModal: true
        })
    }
    return (
        <HeaderContainer>
            <TitleContainer>
                <div>
                    <CorporateHeader>Corporate Account</CorporateHeader>
                    <CompanyName>{company}</CompanyName>
                </div>
                <div>
                    <Search />
                </div>
                <NotificationArea>
                    <NotificationDiv>
                        <MessagePopUp 
                            trigger={<NotificationIcon src={Bell} width={24} height={24}  alt='not' />}
                            arrow={false}
                            >
                            <EmptyNotification id='notification' topic='No New Notification' />
                        </MessagePopUp>                      
                        <MessagePopUp trigger={<NotificationIcon src={Message} alt='not' />}>
                            <EmptyNotification />
                        </MessagePopUp>                      
                    </NotificationDiv>
                    <ImageDiv>
                        <ProfilePicture src={user.data.profileUrl} />
                        <img style={{width:'12px'}} src={DropDown} alt='drops'/>
                    </ImageDiv>
                </NotificationArea>
            </TitleContainer>
            <HeaderMenuArea>
                <SpottrLogo src={Logo} alt='pic' />
                <HeaderMenus>
                        {headerMenuData.map((menu, i) => (
                    <SingleMenu key={i} text={menu.text} link={menu.link}  
                    handleCLickedActive={() => setSubHeaderMenu(i)}
                    Active={subHeaderMenu === i ? "menu-active": null}/>
                ))}
                </HeaderMenus>
                <SpottrLink>
                    <AddNewCategories onClick={productModal}>+ add new products</AddNewCategories>
                </SpottrLink>
            </HeaderMenuArea>
        </HeaderContainer>
    )
}

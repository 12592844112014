import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import LoginPage from 'pages/Login'
import { DASHBOARD, LOGIN, ONBOARDING, SIGNUP, WELCOME } from 'routes/routes';
import JoinPage from 'pages/register';
import Onboarding from 'pages/dashboard/welcomePage';
import WelcomeHomePage from 'pages/dashboard/welcomePage/welcomePage';
import Dashboard from 'pages/dashboard/main';


function App() {
  return (
    // <Div>
      <Router>
        <Switch>
          <Route exact path={LOGIN} component={LoginPage} />
          <Route exact path={SIGNUP} component={JoinPage} />
          <Route exact path={ONBOARDING} component={Onboarding} />
          <Route exact path={WELCOME} component={WelcomeHomePage} />
          <Route  path={DASHBOARD} component={Dashboard} />
        </Switch>
      </Router>
    // </Div>
  );
}

export default App;

import React from 'react'
import {SearchCheckBox} from './style'

export default function Checkbox({isChecked}) {
    return (
        <SearchCheckBox  display={isChecked}>
            {/* <input type='checkbox'  /> */}
        </SearchCheckBox>
    )
}

import media from "GlobalStyles/utils/media";
import styled, { keyframes } from "styled-components/macro";
// import Colors from "styles/utils/colors";

export const SlideIn = keyframes`
from {
    opacity: 0
}
to{
    opacity: 1
}
`;

export const Container = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 85%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top:-180px;
  min-height: 602px;
  max-height: 602px;
  overflow: scroll;
  /* box-shadow: 1px 1px 1px black; */
  padding: 16px;
  left: 7.5%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  transform: ${({ show }) => (show ? `translateY(0)` : `translateY(-20px)`)};
  animation-name: ${SlideIn};
  animation-duration: 0.5s;
  /* animation-delay: 0.3s; */
  p {
    color: grey;
  }

  @media (min-width: 600px) {
    width: 500px;
    left: calc(50% - 250px);
  }
`;

export const OuterContainer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;

  background-color: rgba(0, 0, 0, 0.5);
  transform: ${({ show }) => (show ? `translateY(0)` : `translateY(-100%)`)};
  animation-name: ${SlideIn};
  animation-duration: 0.3s;
`;
export const CancelSection = styled.div`
  display: flex;
  justify-content: flex-end;
  color: grey;

  div {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  text-align: center;
  img {
    height: 50px;
    width: 50px;
  }
`;

export const UserDiv = styled.div`
    display: flex;
    align-items: center;
    /* margin-top: 20px; */
    border-bottom: 1px solid #E7E7E7;
    padding-bottom:3px;



  p{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #000000;
  }

  small{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #C4C4C4;
  }

  Div{
    padding: 10px 0;
  }
`

export const UserSearch = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding:0 10px;

  p{
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #061E48;
  }

  input{
    color: #929AA7;
    background: #F3F3F3;
    border: 0.68px solid #E1EFFB;
    box-sizing: border-box;
    border-radius: 1.92212px;
    padding: 10px;
    width: 70%;

    &::placeholder{
      color: #929AA7;
      font-size:12px;
    }
  }
`

export const Facer = styled.img`
    border-radius: 50%;
    margin-right: 15px;
    border: 3px solid #274B89;
    width: 40px;
    height: 40px;
`

export const SearchCheckBox = styled.div`
  display: ${({display})=>display===false?'flex':'none'};
  width: 19px;
  height: 19px;
  background: #FFFFFF;
  border: 2px solid #274B89;
  border-radius: 3px;

  input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    cursor:pointer;
  }
`

export const Checker = styled.img`
  display: ${({display})=> display==='true'?'block':'none'};
`

export const CheckBoxDiv = styled.div`
  min-width:21px;
  min-height:21px;
  overflow:hidden;
  cursor:pointer;
  margin-right: 10px;
  margin-top: -14px;
`

export const UsersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  max-height: 550px;
  overflow: auto;


  ${media.mobile`
    flex-direction: column;
    max-height: 450px;
    display: block;
  `}

`

export const Holder = styled.div`
  width: 45%;

  ${media.mobile`
    width: 100%;
  `}
`

export const FinishDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  cursor: pointer;
`

export const Back = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #274B89;

`

export const FinishButton = styled.button`
  width: 196.02px;
  height: 41px;
  background: #274B89;
  border-radius: 4px;
  border:none;
  color: white;
  cursor: pointer;
`
import React from 'react'
import GoogleMaps from 'components/dashboard/main/GoogleMap'
import QRCode from 'react-qr-code'
// import product from 'assets/svg/product.svg'
import pencil from 'assets/svg/pencil.svg'
import { AdDiv, BarcodeArea, BottomData, DescriptionArea, EditDiv, MainArea, MapContainer, PriceAndButtonDiv, ProductArea, ProductData, ProductDetailsDiv, ProductImagesDiv, SingleProductContainer } from './style'
import { useAuthContext } from 'context/AuthContext'
import { GETSINGLEPRODUCT } from 'routes/backend'
import addCommas from 'comma-number'
import {ReactComponent as Spinner} from 'assets/svg/spinner.svg'
import Goback from 'components/dashboard/main/goBack'
import { useQuery } from 'react-query'


export default function SingleProduct({match, area="Accra,Ghana",category='Food Services',images=[{},{},{}], description,history}) {
    const id = match.params.id
    const {user} = useAuthContext()

    async function getProduct(){
        const response = await fetch(GETSINGLEPRODUCT(id),{
            headers:{
                Authorization: `Bearer ${user.token}`
            }
        })
        const data = await response.json()
        return data
    }
    

    const {isLoading, data, error} = useQuery(id, getProduct)
   
    return (
        <SingleProductContainer>
            {console.log("datum",data)}
            <Goback handleGoback={history.goBack} />
            <br />
            {isLoading? <Spinner /> : !error?
            <MainArea>
                <ProductArea>
                    <MapContainer>
                        <GoogleMaps 
                            center={
                                {
                                lat: data.data.latitude,
                                lng: data.data.longitude
                            }
                        }
                        >
                        </GoogleMaps>
                        <ProductDetailsDiv>
                            <ProductData>
                                <img src={data.data.images[0]} alt='prod' />
                                <div style={{width:'70%',display:'flex',flexDirection:'column'}}>
                                    <h1>{data.data.productName}</h1>
                                    <h2>{data.data.category[0]?.name}</h2>
                                    <h3>{data.data.user.brandName || 'Coker and user ltd'}</h3>
                                    <BottomData>
                                        <p>{data.data.address}</p>
                                        <button>N{addCommas(data.data.amount)}</button>
                                    </BottomData>
                                </div>
                            </ProductData>
                        </ProductDetailsDiv>
                    </MapContainer>
                    <ProductImagesDiv>
                        {
                            data.data.images.map((image,i)=> <img key={i} src={image}  alt='prod' />)
                        }
                    </ProductImagesDiv>
                    <DescriptionArea>
                        <p>
                            <span>Description:</span>
                            <br />
                            <br />
                            {data.data.description}
                        </p>
                    </DescriptionArea>
                    <PriceAndButtonDiv>
                        <h2>N{addCommas(data.data.amount)}</h2>
                        <button>Post</button>
                        <EditDiv>
                            <img src={pencil} alt='pen'/>
                            <p>Edit Post</p>
                        </EditDiv>
                    </PriceAndButtonDiv>
                </ProductArea>
                <BarcodeArea>
                    <QRCode 
                        bgColor='#ffffff' 
                        fgColor='#274B89' 
                        value={`https://web.thespottrapp.com/product/${id}`}
                        size={250}
                         />
                    <AdDiv>
                        <div>AD</div>
                        <p>Promote Post</p>
                    </AdDiv>
                </BarcodeArea>
            </MainArea>:null
            }
        </SingleProductContainer>
    )
}

import MIcon from 'assets/svg/mIcon.svg'
import GreenUp from 'assets/svg/greenUp.svg'
import Sales from 'assets/svg/salesIcon.svg'
import Customer from 'assets/svg/cusIcon.svg'
import RedUp from 'assets/svg/redUp.svg'

export const detailsData = [
    {
        icon: MIcon,
        title: 'Total Member',
        total: 0,
        updateIcon: GreenUp,
        updateText: '0%',
        isIncreasing: true
    },
    {
        icon: Sales,
        title: 'Total Sales',
        total: 'N0.00',
        updateIcon: GreenUp,
        updateText: '0%',
        isIncreasing: true
    },
    {
        icon: Customer,
        title: 'All Customers',
        total: '0',
        updateIcon: RedUp,
        updateText: '0%',
        isIncreasing: false
    },
]
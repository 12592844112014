import styled from 'styled-components/macro'
import { LoginButton } from 'pages/Login/style'
import media from 'GlobalStyles/utils/media'

export const AddProductDiv = styled.div`
    /* width: 100%;
    height: 100%; */
    display:flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-between; */

`
export const ProductMainArea = styled.div`
    /* width: 100%;
    height: 100%; */
    /* max-height: 550px; */
    /* overflow: auto; */
    display:flex;
    flex-direction: column;
    align-items: center;
`

export const UploadImagesDiv = styled.div`

    width:100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    input{
        position: absolute;
        bottom: -5px;
        /* top:20px; */
        cursor:pointer;
        padding:70px;
        padding-bottom:0;
        width:300px;
        opacity:0;
    }

    img{
        height: 50px;
        width: 50px;
        margin-top: 100px;
    }

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #274B89;
        margin-top: 20px;
    }

    p{
        width: 70%;
        margin-top: 100px;
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: rgba(59, 59, 59, 0.7);
    }
`

export const ShowUploadsDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export const ServiceProviderDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: scroll;

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 158.8%;
        color: #000000;
    }

    textarea{
        margin-top: 20px;
        background-color: #efeeee;
        height: 170px;
        max-height: 220px;
        border-radius: 5px;
        width: 100%;
        max-width: 100%;
        border: none;
        padding: 5px;
        color: #676161;
    }
`

export const TitleText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    /* font-size: 20px; */
    line-height: 27px;
    color: #274B89 !important;
    margin: 0 0 10px 0;
`

export const ServiceProductInput = styled.input`
    height: 36px;
    background: #F8F8F8;
    border-radius: 5px;
    border:none;
    padding: 0 15px;

`

export const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin: 13px 0;
    /* padding: 0 15px; */

    div{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #274B89;
    }
`

export const ProductButton = styled(LoginButton)`
    color: white;
    background-color: #274B89;
    padding: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
    width: 300px;
    margin-top: 20px;

    ${media.mobile`
        max-width: 100%;
    `}
`

export const RestartDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
    }

    h3{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        color: #274B89;
        cursor: pointer;
    }
`
export const UploadDiv = styled.div`
    display:flex;
    height: 358px;
    min-width: 100%;
    max-width: 100%;
    /* overflow: scroll; */

    /* width: 100%; */
    /* justify-content: center; */
    /* overflow: scroll; */

    img{
        margin-right:10px;
        height: 358px;
        /* width: 250px; */
        width: 208px;
        border-radius: 4.8px;
        border: 2px solid gray;
    }
`
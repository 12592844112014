import InputField from 'components/onboading/inputField'
import { LabelText } from 'components/onboading/inputField/style'
import { useRegContext } from 'context/RegContext'
import { Div, SpottrLink } from 'GlobalStyles/spotrrStyles/style'
import BrandColors from 'GlobalStyles/utils/colors'
import React from 'react'
import PasswordForm from './password'
import Phone from './phone'
import { RegText } from './style'





export default function EmailForm({handleFormChange, passwordScore, setPasswordScore}) {
    const {regData} = useRegContext()
    return (
        <>
            <InputField value={regData.form.email || ''} name='email' show='block' handleFormChange={handleFormChange}  placeholder = "Enter a correct email address" displayEye={'false'} type='email' required='true' />
            <LabelText show='block' >Phone Number</LabelText>
            <Phone />
            {/* <InputField placeholder = "Enter your password" type='password' required='true' /> */}
            <PasswordForm passwordScore = {passwordScore} 
                                            setPasswordScore = {setPasswordScore} 
                                            handleFormChange={handleFormChange} />
            <Div
                display= 'flex'
                justify= 'center'
                margin='20px 0 0 0'
            >
                <RegText>By entering your phone, you agree to the <SpottrLink to='/' color={BrandColors.brandColor}> Terms of Service & Privacy Policy </SpottrLink></RegText>
            </Div>
        </>
    )
}

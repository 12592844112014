import styled from 'styled-components/macro'

export const RatedDiv = styled.div`
    width: 83px;
    height: 23px;
    background: rgba(255, 178, 17, 0.15);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
        text-align: center;
        color: #E09B09;
    }
`
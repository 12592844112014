import React from 'react'
import { SoldDiv } from './style'
import SoldIcon from 'assets/mem/soldIcon.png'

export default function Sold() {
    return (
        <SoldDiv>
            <img src={SoldIcon} alt='icon' />
            <p>6,678 Items Sold</p>
        </SoldDiv>
    )
}

import { DASHBOARDHOME, PRODUCTS, WALLETS } from "../ROUTECONST";

export const headerMenuData= [
    {
        text: 'Home',
        isActive: true,
        link: DASHBOARDHOME
    },
    {
        text: 'Wallets',
        isActive: false,
        link: WALLETS
    },
    {
        text: 'Products',
        isActive: false,
        link: PRODUCTS
    }
]
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export const TableRowDiv = styled.tr`
  background-color: #F6F6F6;
  width: 100%;
  opacity: ${({status})=>status==='Blacklisted'?0.4:1};

  td{
    align-items: center;
    text-align: center;
  }
`


export const UserLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;


export const Rate = styled.td`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #274B89;
`
export const Verified = styled.td`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11.0995px;
  align-items: center;
  text-align: center;
  color: #3670D5;
`

export const Stats = styled.td`
  opacity: 1;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 11.0995px;
  align-items: center;
  text-align: center;
  color: ${({status})=>status==='Active'?'#39B54A':status==='Suspended'?'#FF4B3E':'black'} ;
`

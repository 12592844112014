import React from 'react'
import { DataDetailsContainer, 
         FirstLane,
         SecondLane, 
         IconHolder,
         MainText, 
         UpdateDiv,
         UpdatedText} from './style'
import MIcon from 'assets/svg/mIcon.svg'
import GreenUp from 'assets/svg/greenUp.svg'

export default function DataDetails({icon=MIcon,isIncreasing,title='Total Members',total=45,updateIcon=GreenUp,updateText='+34.98%'}) {
    return (
        <DataDetailsContainer>
            <FirstLane>
                <MainText>{total}</MainText>
                <IconHolder>
                    <img src={icon} alt='ikon' />
                </IconHolder>
                
            </FirstLane>
            <SecondLane>
                <small>{title}</small>
                <UpdateDiv>
                   <UpdatedText isIncreasing={isIncreasing}>{updateText}</UpdatedText>
                   <img src={updateIcon} alt='icon' /> 
                </UpdateDiv>
            </SecondLane>
        </DataDetailsContainer>
    )
}

import React from 'react'
import LocationIcon from 'assets/svg/locationIcon.svg'
import { LiveText, LocationFormContainer, LocationText, MapDiv, ManualText  } from './style'
import SpottrPlaces from 'components/dashboard/main/GoogleMap/reactPlaces'


export default function LocationForm({corporateUser, setCorporateUser}) {

    // function currentLocation(){
        // const {latitude, longitude} = usePosition()
    // }

    const {lat,lng} = corporateUser.data

    // function handleChange(e){
    //     setCorporateUser({
    //         ...corporateUser,
    //         data:{
    //             ...corporateUser.data,
    //             [e.target.name]:e.target.value
    //         }
    //     })
    // }
    return (
        <LocationFormContainer>
            {/* {console.log(latitude,longitude)} */}
            <LocationText>Set Company Location</LocationText>
            <MapDiv>
            {/* {lat: 4.9696741, lng: 6.1054756} */}
                {/* <GoogleMaps  /> */}
            {/* <iframe title='gMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.2005706469918!2d3.5942683142665137!3d6.496272125282775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bfb5e7f41143b%3A0x1b9905611ae30af9!2sSpottr!5e0!3m2!1sen!2sng!4v1621400037548!5m2!1sen!2sng" border='0' loading="lazy"></iframe> */}
            <iframe title="gMap" src={`https://www.google.com/maps/embed/v1/place?q=${lat},${lng}&key=${`AIzaSyDqoTtDYnAr80Z6wvOY9Qp4FKtMfeoaNdM`}`}></iframe>
            </MapDiv>
            <ManualText>or manually input</ManualText>
            <SpottrPlaces corporateUser={corporateUser} setCorporateUser={setCorporateUser} />
            <LiveText><img src={LocationIcon} alt='location' />Use life location</LiveText>

        </LocationFormContainer>
    )
}

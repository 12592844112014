import {
  Button,
  Div,
  SpottrH,
  SpottrInput,
  SpottrP,
} from "GlobalStyles/spotrrStyles/style";
import Colors from "GlobalStyles/utils/appColors";
import React from "react";
import { TransactionpinContainer } from "./style";

function Transactionpin() {
  return (
    <TransactionpinContainer>
      <Div display="flex" justify="flex-end">
        <Button
          bc={Colors.lightGrey}
          p="10px 20px"
          color={Colors.grey}
          br="5px"
        >
          Done
        </Button>
      </Div>
      <Div display="flex" flexDirection="column">
        <SpottrP color={Colors.primary} mt="25px">Set Transaction PIN</SpottrP>
        <SpottrH color={Colors.primary} fw="bold" fs="20px" mt="25px" mb="15px">Transaction PIN</SpottrH>
      </Div>
      <SpottrInput
        bg="#F8F8F8"
        w="100%"
        h="54px"
        type="text"
        
        placeholder="****"
      />
      <Div display="flex" mt="25px">
        <SpottrP tAlign="center">
          Kindly input the transaction PIN you registered while setting up your
          profile
        </SpottrP>
      </Div>
    </TransactionpinContainer>
  );
}

export default Transactionpin;
